import { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import Modal from "react-modal";

import Checkbox from "../../forms/checkbox";
import { MeContext } from "../../../context/UserContext";
import DefaultAvatar from "../../../img/default-avatar.png";
import asyncAPICall from "../../../util/apiWrapper";
import CreateListForm from "../../CreateListForm";

import { errorToast } from "../../../util/toastNotifications";

const headerFields = [
  { id: "first_name", header: "First Name" },
  { id: "last_name", header: "Last Name" },
];

Modal.setAppElement("#root");

const AllFamilyMembers = () => {
  const { me } = useContext(MeContext);
  const [allFamilyMembers, setAllFamilyMembers] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selectedList, setSelectedList] = useState("All Family Members");
  const [userLists, setUserLists] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [toggleSort, setToggleSort] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const hoverRef = useRef();

  useEffect(() => {
    asyncAPICall(
      `/user/lists/${me.user_id}`,
      "GET",
      null,
      null,
      (data) => {
        data.lists.forEach((list) => {
          list.users.forEach((user) => {
            user.isChecked = false;
          });
        });

        setUserLists(data.lists);
      },
      null
    );

    asyncAPICall(
      "/user/family-get-all",
      "GET",
      null,
      null,
      (data) => {
        setFamilyMembers(data.filter((user) => user.user_id !== me.user_id));
        setAllFamilyMembers(data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [me.user_id]);

  const handleFilter = (filter) => {
    setSelectedList(filter);
    setFamilyMembers(
      filter === "All Family Members"
        ? allFamilyMembers.filter((person) => person.user_id !== me.user_id)
        : userLists
            .find((list) => list.list_name === filter)
            .users.filter((user) => user.user_id !== me.user_id)
    );
  };

  const handleSelectAll = (e) => {
    let stateUsers = [...familyMembers];

    stateUsers.forEach((user) => {
      user.isChecked = e.target.checked;
    });

    setSelected(e.target.checked ? stateUsers : []);
    setSelectAll((sa) => !sa);
  };

  const handleSort = (e) => {
    let members =
      selectedList !== "All Family Members"
        ? userLists.map((list) => list.users)
        : [...familyMembers];

    if (toggleSort) {
      members.sort((a, b) => {
        return a[e.target.id] < b[e.target.id] && -1;
      });
    } else {
      members.sort((a, b) => {
        return a[e.target.id] > b[e.target.id] && -1;
      });
    }

    setFamilyMembers(members);
    setToggleSort((t) => !t);
  };

  const renderListOptions = () => {
    return userLists.map((list) => {
      return (
        <option key={list.list_id} value={list.list_name}>
          {list.list_name}
        </option>
      );
    });
  };

  const renderFamilyMembers = () => {
    return familyMembers.map((member) => {
      return (
        <tr key={member.user_id}>
          <td>
            <Checkbox
              selected={selected}
              setSelected={setSelected}
              person={member}
            />
          </td>
          <td>
            <Link to={`/home/${member.user_id}`}>
              <img
                src={member.avatar_link || DefaultAvatar}
                alt="family member"
              />
            </Link>
          </td>
          <td>{member.first_name}</td>
          <td>{member.last_name}</td>
          <td>{member.email}</td>
          <td>{member.phone}</td>
          <td>{member.city}</td>
          <td>{member.state}</td>
          <td>{member.verified ? "True" : "False"}</td>
        </tr>
      );
    });
  };

  const renderHeaderFields = () => {
    return headerFields.map((header) => {
      return (
        <td
          key={header.id}
          className="table-title"
          id={header.id}
          onClick={handleSort}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
        >
          {header.header}
          <i
            id={header.id}
            className={`fas fa-sort-${toggleSort ? "up" : "down"}`}
          />
        </td>
      );
    });
  };

  const handleEnter = (e) => {
    hoverRef.current = null;
    hoverRef.current = e.target;

    if (hoverRef.current.children[0]) {
      hoverRef.current.children[0].style.display = "inline-flex";
    }
  };

  const handleLeave = () => {
    hoverRef.current.children[0].style.display = "none";
    hoverRef.current = null;
  };

  const handleSearch = (e) => {
    const isFiltered = selectedList !== "All Family Members";
    const isValidValue = e.target.value.length > 0;

    if (!isValidValue && !isFiltered) {
      setFamilyMembers(
        allFamilyMembers.filter((person) => person.user_id !== me.user_id)
      );
    } else if (!isValidValue && isFiltered) {
      setFamilyMembers(
        userLists
          .find((list) => list.list_name === selectedList)
          .users.filter((user) => user.user_id !== me.user_id)
      );
    } else if (isValidValue && !isFiltered) {
      setFamilyMembers(
        allFamilyMembers
          .filter((member) => {
            return (
              member.first_name
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) ||
              member.last_name
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
            );
          })
          .filter((user) => user.user_id !== me.user_id)
      );
    } else if (isValidValue && isFiltered) {
      setFamilyMembers(
        userLists
          .find((list) => list.list_name === selectedList)
          .users.filter((member) => {
            return (
              member.first_name
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) ||
              member.last_name
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
            );
          })
          .filter((user) => user.user_id !== me.user_id)
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.65)",
            overflow: "hidden",
          },
          content: {
            position: "absolute",
            maxWidth: "100%",
            top: "10%",
            left: "25%",
            right: "25%",
            bottom: "25%",
            border: "1px solid white",
            background: "#FAFAFA",
            overflow: "scroll",
            borderRadius: "10px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <CreateListForm
          users={selected}
          setModalIsOpen={setModalIsOpen}
          user_id={me.user_id}
        />
      </Modal>
      <div className="all-family-members-page-wrapper">
        <div className="title-button-wrapper">
          <div className="page-title">
            <h1>{selectedList}</h1>
          </div>
          <div className="button-container">
            <div className="general-list-buttons">
              <select
                name="Family List"
                id="family-list-dropdown"
                onChange={(e) => handleFilter(e.target.value)}
              >
                <option value="All Family Members">All Family</option>
                {renderListOptions()}
              </select>
              {selected.length ? (
                <CSVLink data={selected}>
                  <button> Export Selected</button>
                </CSVLink>
              ) : (
                <button disabled> Export Selected</button>
              )}
            </div>
            <div className="list-action-buttons">
              <button
                className="create-list-button"
                disabled={
                  selected.length > 0 && selectedList === "All Family Members"
                    ? false
                    : true
                }
                onClick={() => {
                  setModalIsOpen(true);
                }}
              >
                Create List From Selected
              </button>

              {selected.filter((person) => !person.verified).length > 0 ? (
                <button
                  disabled={selected.length > 0 ? false : true}
                  className="selected-btn"
                  onClick={() =>
                    errorToast("Cannot send messages to unverified persons")
                  }
                >
                  Send Message to Selected
                </button>
              ) : (
                <Link
                  className="send-message-btn"
                  to={{
                    pathname: "/send-message",
                    state: {
                      recipients: selected.map((user) => [
                        ` ${user.first_name} ${user.last_name}`,
                      ]),
                      recipientsEmail: selected.map((user) => user.email),
                      sender: me.email,
                    },
                  }}
                >
                  <button
                    disabled={selected.length > 0 ? false : true}
                    className="selected-btn"
                  >
                    Send Message to Selected
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="search-wrapper">
          <input type="text" onChange={handleSearch} placeholder="Search" />
        </div>
        {familyMembers.length > 0 ? (
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <td className="table-title">
                    <input
                      type="checkbox"
                      className="select-all-toggle"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                    Select All
                  </td>
                  <td className="table-title">Avatar</td>

                  {renderHeaderFields()}
                  <td className="table-title">Email</td>
                  <td className="table-title">Phone Number</td>
                  <td className="table-title">City</td>
                  <td className="table-title">State</td>
                  <td className="table-title">Verified</td>
                </tr>
              </thead>
              <tbody>{renderFamilyMembers()}</tbody>
            </table>
          </div>
        ) : (
          <div className="no-results">
            <h1>No Results</h1>
          </div>
        )}
      </div>
    </>
  );
};

export default AllFamilyMembers;
