import { useState } from "react";

import DefaultAvatar from "../../img/default-avatar.png";
import asyncAPICall from "../../util/apiWrapper";

const RemoveFamilyMemberForm = (props) => {
  const { family, setFamily, userData, setRemoveFamilyModal } = props;
  const [response, setResponse] = useState(0);
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [completeRemoval, setCompleteRemoval] = useState(false);
  const [removeSelected, setRemoveSelected] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const handleChange = (user_id, label) => {
    setRemoveSelected(user_id);
    setSelectedType(label.toLowerCase());
    setChecked(true);
    setDisabled(true);
  };

  const handleMemberRemove = (userId, relationshipType) => {
    const body = {
      user_one_id: userData.user_id,
      user_two_id: removeSelected,
      relationship_type: selectedType,
    };

    const removePromise = new Promise((resolve, reject) => {
      asyncAPICall(
        "/remove-relationships",
        "POST",
        body,
        (res) => {
          if (res.status === 200) {
            setFamily((prevFamily) => {
              const filteredMember = prevFamily[relationshipType].filter(
                (selectedMember) => selectedMember.user_id !== userId
              );

              return {
                ...prevFamily,
                [relationshipType]: filteredMember,
              };
            });
            setResponse(res.status);
            resolve(true);
          }
        },
        null,
        (err) => {
          setCompleteRemoval(true);
          reject("Remove family Error", err);
        }
      );
    });

    removePromise.catch(console.error).finally(() => {
      setCompleteRemoval(true);
      setChecked(false);
      setDisabled(false);
      setSelectedType("");
    });
  };

  const renderModalFamily = (relType, label) => {
    return family[relType]?.map((member) => {
      return (
        <div key={member.user_id} className="modal-family-wrapper">
          <div className="individual-wrapper">
            <div className="personal-info">
              <img
                className="family-member-img"
                src={member.avatar_link || DefaultAvatar}
                alt="Family member avatar"
              />

              <div>
                {member.first_name} {member.last_name}, {label}
              </div>
            </div>

            <button
              onClick={() => {
                handleChange(member.user_id, label);
              }}
              disabled={disabled}
            >
              X
            </button>
          </div>

          {checked && member.user_id === removeSelected && (
            <div className="confirm-remove">
              <div>Are you sure you want to remove {member.first_name}?</div>

              <button
                className="yes-btn"
                onClick={() => handleMemberRemove(member.user_id, relType)}
              >
                Yes, remove
              </button>

              <button
                className="no-btn"
                onClick={() => {
                  setChecked(false);
                  setDisabled(false);
                  setSelectedType("");
                }}
              >
                No, don't remove
              </button>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="remove-family-modal">
        {!completeRemoval && (
          <div className="exit-button">
            <button
              onClick={() => {
                setRemoveFamilyModal(false);
              }}
            >
              <i className="fas fa-times" />
            </button>
          </div>
        )}

        {!completeRemoval && <h2>Remove</h2>}
        {!completeRemoval && renderModalFamily("parents", "Parent")}
        {!completeRemoval && renderModalFamily("children", "Child")}
        {!completeRemoval && renderModalFamily("spouse", "Spouse")}

        <div className="removal-msg">
          {completeRemoval && response === 200 ? (
            <div className="success-msg">Success!</div>
          ) : (
            completeRemoval &&
            response !== 200 && (
              <div className="unsuccessful-msg">Removal Unsuccessful</div>
            )
          )}

          {completeRemoval && (
            <button
              className="return-btn"
              onClick={() => {
                setRemoveFamilyModal(false);
                setCompleteRemoval(false);
              }}
            >
              Return to page
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default RemoveFamilyMemberForm;
