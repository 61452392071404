import { Link } from "react-router-dom";

import Logo from "../../img/FamLincLogo.png";

const LearnMore = () => {
	return (
		<div className="learn-more-page-wrapper">
			<div className="logo-wrapper">
				<Link to="/login">
					<img src={Logo} alt="famlinc-logo" />
				</Link>
			</div>
			<div className="content-wrapper">
				<p>
					Discover the best way to connect with family and relatives all in one
					place. You can create family lists, send group email to notify of
					events, or connect with long lost relatives. FamLinc is A great place
					to reach out to those you love.
				</p>
				<Link to="/register">
					<button className="button-dark">Sign Up</button>
				</Link>
			</div>
		</div>
	);
};

export default LearnMore;
