import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "../styles/App.scss";
import UserContext from "../context/UserContext";
import LandingPage from "./pages/home/landingPage.js";
import SignupPage from "./pages/signup/signupPage.js";
import signupConfirmation from "./pages/signup/signupConfirmation.js";
import LoginContainer from "./loginContainer";
import DefaultContainer from "./defaultContainer";
import Timeout from "./pages/timeout";
import VerificationThankYou from "./pages/signup/verificationThankyou";
import EmailVerification from "./pages/signup/emailVerification";
import EncourageSignUp from "./pages/signup/encourageSignUp";
import InvitePage from "./pages/invitePage";
import CheckEmail from "./pages/signup/CheckEmail";
import LearnMore from "./pages/learnMore";
import MergeConfirmation from "./pages/mergeConfirmation";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/register" component={SignupPage} />
          <Route path="/session-timeout" component={Timeout} />
          <Route path="/learn-more" component={LearnMore} />
          <Route path="/stay-connected" component={EncourageSignUp} />
          <Route path="/invitation" component={InvitePage} />
          <Route path="/check-email" component={CheckEmail} />
          <Route path="/sign-up-confirmation" component={signupConfirmation} />
          <Route path="/verify-email" component={EmailVerification} />
          <Route path="/confirm/:encoded" component={VerificationThankYou} />
          <Route path="/merge/:mergeToken" component={MergeConfirmation} />

          <UserContext>
            <Route path="/login" component={LoginContainer} />

            <DefaultContainer />
          </UserContext>

          <Redirect exact from="/" to="/login" />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
