import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Logo from "../../img/famlinc-logo.png";
import asyncAPICall from "../../util/apiWrapper";

const InvitePage = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [validToken, setValidToken] = useState(true);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({});
  const encoded = props.match.params.encoded;
  const isExpired =
    Date.parse(window.atob(encoded).split("expiration=")[1]) < Date.now();
  const encodedString = window.btoa(
    window.atob(encoded).split("expiration=")[0]
  );

  useEffect(() => {
    if (confirmPassword !== "" && password === confirmPassword) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  }, [confirmPassword, password]);

  useEffect(() => {
    if (data.token) {
      setValidToken(false);
    } else if (data.length === 2) {
      setSuccess(true);
    }
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    asyncAPICall(
      `/user_claim/${encodedString}`,
      "POST",
      {
        password: password,
      },
      null,
      (res) => {
        setData(res);
      },
      (err) => {
        console.error(err);
      },
      false
    );
  };

  if (success) {
    return (
      <div className="invite-page-wrapper">
        <div className="logo-wrapper">
          <Link to="/login">
            <img src={Logo} alt="FamLinc-logo" />
          </Link>
        </div>
        <h2>
          You have successfully signed up! Login <Link to="/login">here</Link>.
        </h2>
      </div>
    );
  }

  if (isExpired) {
    return (
      <div className="invite-page-wrapper">
        <div className="logo-wrapper">
          <Link to="/login">
            <img src={Logo} alt="FamLinc-logo" />
          </Link>
        </div>

        <div className="text-wrapper">
          <h1>Looks like your token has expired.</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="invite-page-wrapper">
      <div className="logo-wrapper">
        <Link to="/login">
          <img src={Logo} alt="FamLinc-logo" />
        </Link>
      </div>

      {validToken && (
        <div className="text-wrapper">
          <h1>The best way to stay connected with your family!</h1>
          <p>Provide a password to complete the creation of your account.</p>

          <form onSubmit={handleSubmit}>
            <div className="password">
              <input
                className="enter-password"
                type="password"
                placeholder="Enter a password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />

              <input
                className="confirm-password"
                type="password"
                placeholder="Confirm password"
                name="confirm-password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                value={confirmPassword}
              />
            </div>

            {confirmPassword !== "" ? (
              validPassword ? (
                <label style={{ color: "green" }} htmlFor="confirm-password">
                  Passwords Match!
                </label>
              ) : (
                <label style={{ color: "red" }} htmlFor="confirm-password">
                  Passwords don't match.
                </label>
              )
            ) : (
              <div></div>
            )}

            <button type="submit" disabled={validPassword ? false : true}>
              Submit
            </button>
          </form>
        </div>
      )}

      {!validToken && (
        <div className="text-wrapper">
          <h1>
            Oops! It looks like you've already used your token. <br />
            Login <Link to="/login">here</Link>.
          </h1>
        </div>
      )}
    </div>
  );
};

export default InvitePage;
