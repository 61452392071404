import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import asyncAPICall from "../util/apiWrapper";
import useAbortEffect from "./hooks/useAbortEffect";
import SearchResultItem from "./pages/search/searchResultItem";

const AddUserToList = (props) => {
  const listID = useParams();
  const [search, setSearch] = useState("");
  const [members, setMembers] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [response, setResponse] = useState(0);

  useAbortEffect(
    (signal) => {
      asyncAPICall(
        "/user/family-get-all",
        "GET",
        null,
        null,
        (data) => {
          if (props.listUsers?.length > 0) {
            const filteredMembers = [];
            const usersIdsList = [];

            for (let person of props.listUsers) {
              usersIdsList.push(person.user_id);
            }

            for (let person of data) {
              if (!usersIdsList.includes(person.user_id)) {
                filteredMembers.push(person);
              }
            }

            setMembers(filteredMembers);
          } else {
            setMembers(data);
            setSearchResults(data);
          }
        },
        (err) => {
          if (!signal.aborted) {
            console.log(err);
          }
        },
        true,
        null,
        true,
        signal
      );
    },
    [props]
  );

  useEffect(() => {
    if (search) {
      setSearchResults(
        members.filter((person) => {
          return (
            person.first_name.toLowerCase().includes(search) ||
            person.last_name.toLowerCase().includes(search)
          );
        })
      );
    } else {
      setSearchResults(members);
    }
  }, [search, members]);

  const handleAddUser = (userID) => {
    let addListID = Object.values(listID)[0];

    if (!props.setUsers) {
      asyncAPICall(
        "/list/add-user",
        "POST",
        {
          new_user_id: userID,
          list_id: addListID,
        },
        (response) => {
          setResponse(response.status);
          setSearchResults((r) => r.filter((user) => user.user_id !== userID));
        },
        null,
        (err) => {
          console.error("Add user error: ", err);
        },
        true
      );
    } else {
      props.setUsers((u) => [...u, userID]);
    }
  };

  const renderSearchResults = () => {
    if (searchResults.length) {
      return searchResults.map((person) => {
        return (
          <div
            key={person.user_id}
            className="user-item-wrapper"
            onClick={() => handleAddUser(person.user_id)}
          >
            <SearchResultItem
              person={person}
              key={person.user_id}
              displayCheckbox={false}
            />
          </div>
        );
      });
    } else {
      return <h1>No matching users</h1>;
    }
  };

  return (
    <div className="add-user-form-wrapper">
      <h2 className={response < 400 ? "successful-msg" : "unsuccessful-msg"}>
        {response > 0 && response < 400
          ? "Successfully added!"
          : response === 400
          ? "User already added."
          : ""}
      </h2>
      <h1>Add User To List</h1>
      <input
        type="text"
        placeholder="SEARCH"
        onChange={(e) => setSearch(e.target.value.toLowerCase())}
      />
      <div className="results">{renderSearchResults()}</div>
    </div>
  );
};

export default AddUserToList;
