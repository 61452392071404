import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import Logo from "../../../img/famlinc-logo.png";
import Button from "../../core/Button";
import { MeContext } from "../../../context/UserContext";
import { awaitAPICall } from "../../../util/apiWrapper";

export default function LoginPage(props) {
  const { setAuthToken, setAuthIsLoading, logout, auth_token } =
    useContext(MeContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error_msg, setErrorMsg] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [isPassword, setIsPassword] = useState(false);

  const validate = () => {
    if (isEmail && isPassword === true) {
      return !isLoading;
    } else {
      return isLoading;
    }
  };

  useEffect(() => {
    if (auth_token) {
      logout();
    }
  }, [auth_token, logout]);

  function handleSubmit(event) {
    setIsLoading(true);
    event.preventDefault();

    let form_body = new FormData(event.target);

    awaitAPICall(
      "/user/auth",
      "POST",
      Object.fromEntries(form_body),
      (response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          setErrorMsg("Invalid Email/Password");
          setIsLoading(false);
          return null;
        } else if (response.status === 403) {
          setIsLoading(false);
          setErrorMsg(
            <>
              You need to Validate Your Account. Click{" "}
              {<Link to="/verify-email">here to be redirected.</Link>}
            </>
          );
          return null;
        }
      },
      (data) => {
        if (data) {
          let auth_token = data.auth_info.auth_token;
          let user_role = data.user_info.role;
          let user_id = data.user_info.user_id;
          let user_name = data.user_info.first_name;

          Cookies.set("auth_token", auth_token);
          Cookies.set("user_role", user_role);
          Cookies.set("user_id", user_id);
          Cookies.set("user_name", user_name);
          Cookies.set("auth_expires", data.auth_info.expiration);

          setAuthToken(auth_token);
          setAuthIsLoading(false);
          props.history.push(`/home/${data.user_info.user_id}`);
        }
      },
      (err) => {
        console.error("Login Error: ", err);
        setIsLoading(false);
      },
      false
    );
  }

  return (
    <div className="wrapper">
      <Link to="/">
        <img src={Logo} alt="FamLinc-logo" />
      </Link>

      <div className="login-wrapper">
        <div className="form-wrapper">
          <h2>Please log in</h2>
          <div className="login-error-message">{error_msg}</div>
          <form className="form" onSubmit={handleSubmit}>
            <div className="input-wrapper">
              <input
                id="email"
                name="email"
                type="email"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setIsEmail(false);
                  } else {
                    setIsEmail(true);
                  }
                }}
                placeholder="Enter email address"
                autoComplete="email"
                width="10px"
                required
              />

              <input
                id="password"
                name="password"
                type="password"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setIsPassword(false);
                  } else {
                    setIsPassword(true);
                  }
                }}
                placeholder="Enter password"
                autoComplete="current-password"
                required
              />

              <Button
                variant="dark"
                type="submit"
                isDisabled={!validate()}
                command={
                  isLoading ? <i className="fas fa-spinner fa-spin" /> : "Login"
                }
              />

              <div className="alternative-link-wrapper">
                <Link className="no-decoration" to="/login/password/recovery">
                  Forgot Password?
                </Link>

                <Link id="no-account" className="no-decoration" to="/register">
                  Don't have an account?
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
