import React from "react";
import Logo from "../../img/FamLincLogo.png";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  return (
    <div className="fp-page-wrapper">
      <div className="forgot-password-wrapper">
        <div className="logo">
          <Link to="/login">
            <img src={Logo} alt="Logo"></img>
          </Link>
        </div>
        <div>
          <h1>Recover Password</h1>
        </div>
        <div>
          <h2>
            A recovery link will be sent shortly. Please check your email.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
