import { useCallback, useContext, useEffect, useState } from "react";
import Modal from "react-modal";

import useDebounce from "../../hooks/useDebounce";
import useWindowSize from "../../hooks/useWindowSize";
import useAbortEffect from "../../hooks/useAbortEffect";
import SearchResultItem from "./searchResultItem";
import { awaitAPICall } from "../../../util/apiWrapper";
import Loading from "../../core/loading";
import AddUserForm from "../addUserForm";
import { MeContext } from "../../../context/UserContext";

Modal.setAppElement("#root");

const SearchResults = (props) => {
  const searchDebounce = useDebounce(props.searchTerm);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { me } = useContext(MeContext);
  const { width } = useWindowSize();
  const [modalStyle, setModalStyle] = useState({
    content: {
      top: "25%",
      left: "25%",
      bottom: "25%",
      right: "25%",
    },
  });

  useAbortEffect(
    (signal) => {
      setModalStyle((ms) => {
        const styles =
          width <= 500
            ? {
                top: "10%",
                left: "5%",
                right: "5%",
                bottom: "10%",
              }
            : {
                top: "25%",
                left: "25%",
                right: "25%",
                bottom: "25%",
              };

        return { ...ms, content: { ...ms.content, ...styles } };
      });
    },
    [width]
  );

  const getResults = useCallback(() => {
    awaitAPICall(`/search/${searchDebounce}`, "GET", {}, null, (data) => {
      setSearchResults(data);
      setIsSearching(false);
    });
  }, [searchDebounce]);

  const renderSearchResults = () => {
    return searchResults.map((person) => {
      return (
        <SearchResultItem
          key={person.user_id}
          person={person}
          displayCheckbox={false}
          displayLink={true}
          displayRelationship={true}
        />
      );
    });
  };

  useEffect(() => {
    if (props.searchTerm === "") setIsSearching(false);
    else setIsSearching(true);
  }, [props.searchTerm]);

  useEffect(() => {
    if (searchDebounce) {
      getResults();
    } else {
      setSearchResults([]);
      setIsSearching(false);
    }
  }, [searchDebounce, getResults]);

  return (
    <div className="search-results-page-wrapper">
      <div className="text-wrapper">
        <h3>Search Results</h3>
      </div>
      <div className="search-results-container">
        {isSearching ? (
          <div
            style={{
              height: "50%",
              width: "50%",
            }}
          >
            <Loading content="Searching..." />
          </div>
        ) : searchResults?.length ? (
          renderSearchResults()
        ) : (
          <div className="no-results-wrapper">
            <div className="text">
              <h2>No results found...</h2>

              <div className="addbutton">
                <button onClick={() => setModalIsOpen(true)}>Add User</button>
              </div>
            </div>
            <Modal isOpen={modalIsOpen} style={modalStyle}>
              <div className="exit-button">
                <button onClick={() => setModalIsOpen(false)}>
                  <i className="fas fa-times"></i>
                </button>
              </div>{" "}
              <AddUserForm
                me={me.user_id}
                setModalIsOpen={setModalIsOpen}
                setSearchTerm={props.setSearchTerm}
                push={props.history.push}
              />
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
