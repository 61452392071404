import { useEffect, useState } from "react";

const Checkbox = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheck = (e) => {
    const { setSelected, selected } = props;
    if (isChecked) {
      const filteredUsers = selected.filter(
        (user) => String(user.user_id) !== e.target.id
      );

      setSelected(filteredUsers);
    } else {
      setSelected([...selected, props.person]);
    }

    setIsChecked((c) => !c);
  };

  useEffect(() => {
    setIsChecked(props.person.isChecked || props.checkboxChecked);
  }, [props.person.isChecked, props.checkboxChecked]);

  return (
    <input
      className="search-result-checkbox"
      id={props.person.user_id}
      checked={!!isChecked}
      type="checkbox"
      onChange={handleCheck}
    />
  );
};

export default Checkbox;
