import { Link } from "react-router-dom";

import Logo from "../../../img/FamLincLogo.png";
import CartoonFamily from "../../../img/cartoon-family.png";

export default function LandingPage() {
  return (
    <div className="landing-page-wrapper">
      <div className="header-wrapper">
        <div className="left-side-wrapper">
          <img className="famlinc-logo" src={Logo} alt="famlinc logo" />
        </div>

        <div className="right-side-wrapper">
          <div className="btn-dark">
            <Link to="/login">
              <button style={{ cursor: "pointer" }}>Login</button>
            </Link>
          </div>

          <div className="btn-dark">
            <Link to="/register">
              <button style={{ cursor: "pointer" }}>Sign Up</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="slogan-wrapper">
        <div className="p-wrapper">
          <p>
            Stay Connected With Family <br /> Easier Than Ever Before
          </p>
          <div className="link-wrapper btn-dark">
            <Link to="/learn-more">
              <button>LEARN MORE</button>
            </Link>
          </div>
        </div>

        <div className="landing-page-image">
          <div className="img-wrapper">
            <img
              src={CartoonFamily}
              alt="cartoon family"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
