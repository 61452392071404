import { useState, useEffect, useMemo, useReducer, useContext } from "react";

import { isValidEmail } from "../../util/stringUtils";
import asyncAPICall from "../../util/apiWrapper";
import Loading from "../core/loading";
import Button from "../core/Button";
import { MeContext } from "../../context/UserContext";
import useAbortEffect from "../hooks/useAbortEffect";
import { successfulToast } from "../../util/toastNotifications";

const initialUserState = {
  user_id: "",
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  phone: "",
  role: "user",
  birth_date: "",
  active: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setOne":
      return { ...state, [action.key]: action.payload };
    case "setAll":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default function UserForm(props) {
  const [userState, dispatch] = useReducer(reducer, initialUserState);
  const [editing, setEditing] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [deceased, setDeceased] = useState(false);
  const [deathDate, setDeathDate] = useState("");
  const [deathDateError, setDeathDateError] = useState(false);
  const [deathBirthOrderError, setDeathBirthOrderError] = useState(false);
  const [birthDate, setBirthDate] = useState("");
  const [birthDateError, setBirthDateError] = useState(false);
  const [invite, setInvite] = useState(false);
  const title = useMemo(() => (editing ? "Edit User" : "Add User"), [editing]);
  let today = new Date();
  let maxValue = today.toISOString().split("T")[0];
  const { me } = useContext(MeContext);

  useAbortEffect(
    (signal) => {
      const user_id = props.match.params.user_id;

      if (user_id) {
        setIsLoading(true);

        const userGetByIdPromise = new Promise((resolve, reject) => {
          asyncAPICall(
            `/user/get/${user_id}`,
            "GET",
            null,
            null,
            (data) => {
              if (data) {
                if (data.birth_date) {
                  data.birth_date = data.birth_date.slice(0, 10);
                }

                if (data.death_date) {
                  data.death_date = data.death_date.slice(0, 10);
                }

                const payload = {};

                for (let key in data) {
                  if (data[key]) {
                    payload[key] = data[key];
                  }
                }

                setDeathDate(payload.death_date);
                dispatch({ type: "setAll", payload });
                resolve(true);
              }
            },
            (err) => reject("User Get By ID: ", err),
            true,
            null,
            null,
            signal
          );
        });

        userGetByIdPromise.catch(console.error).finally(() => {
          setEditing(true);
          setErrorMsg(false);
          setIsLoading(false);
        });
      }
    },
    [props.match.params.user_id]
  );

  useEffect(
    (signal) => {
      setBirthDateError(() => {
        if (Date.now() < Date.parse(birthDate)) {
          return true;
        } else {
          return false;
        }
      });

      setDeathDateError(() => {
        if (Date.now() < Date.parse(deathDate)) {
          return true;
        } else {
          return false;
        }
      });

      setDeathBirthOrderError(() => {
        if (Date.parse(deathDate) < Date.parse(birthDate)) {
          return true;
        } else {
          return false;
        }
      });
    },
    [birthDate, deathDate]
  );

  useEffect(() => {
    if (!userState.email) {
      setEmailError(false);
    } else {
      setEmailError(!isValidEmail(userState.email));
      setDuplicateEmail(false);
    }
  }, [userState.email]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const fetch_url = editing ? "update" : "add";

    let form_body = new FormData(event.target);
    let body = Object.fromEntries(form_body);
    body.me_id = me.user_id;
    if (editing === true) {
      body.active = JSON.parse(body.active);
    } else if (!editing && deathDate) {
      body.death_date = deathDate;
      body.living_status = JSON.parse(false);
    }

    asyncAPICall(
      `/user/${fetch_url}`,
      "POST",
      body,
      (response) => {
        if (response.status < 400) {
          props.history.push("/admin/users");
          if (!editing && invite) {
            inviteUser();
          }
          return response.json();
        }
      },
      null,
      (error) => {
        setErrorMsg(true);
        console.error("Error: ", error);
      }
    );

    function inviteUser() {
      asyncAPICall(
        `/user/invite`,
        "POST",
        { invite_email: userState.email },
        (res) => {
          if (res.status < 400) {
            successfulToast("Successfully invited user.");
          }
        },
        null,
        (err) => {
          console.log("Invite user error: ", err);
          setErrorMsg(true);
        }
      );
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "birth_date") {
      setBirthDate(event.target.value);
    }

    dispatch({
      type: "setOne",
      key: event.target.name,
      payload:
        event.target.name === "active"
          ? JSON.parse(event.target.value)
          : event.target.value,
    });
  };

  const checkValidEmail = () => {
    if (userState.email !== "") {
      if (!emailError) {
        asyncAPICall(
          "/check-email",
          "POST",
          {
            email: userState.email,
          },
          (res) => {
            if (res.status >= 400) {
              setDuplicateEmail(true);
            } else {
              setEmailError(false);
              setDuplicateEmail(false);
            }
          },
          null,
          (err) => console.error("Duplicate Email Error: ", err),
          false
        );
      }
    }
  };

  return (
    <div className="wrapper">
      <div className="form-field-wrapper">
        <div className="form-wrapper" elevation={3}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <h2>{title}</h2>

              <div className="error-message">
                {errorMsg && "Something Went Wrong."}
              </div>

              <form className="form" onSubmit={handleSubmit}>
                <label htmlFor="role" className="drop-down-label">
                  Role
                </label>
                <div className="role-wrapper">
                  <select
                    name="role"
                    defaultValue={userState.role}
                    onChange={handleChange}
                  >
                    <option value="user">User</option>
                    <option value="admin">Admin</option>

                    {me.role === "super-admin" && (
                      <option value="super-admin">Super Admin</option>
                    )}
                  </select>
                </div>

                <label htmlFor="first_name">First Name *</label>
                <input
                  required
                  id="first_name"
                  name="first_name"
                  type="text"
                  value={capitalizeFirstLetter(userState.first_name)}
                  onChange={handleChange}
                  size="small"
                />

                <label htmlFor="last_name">Last Name *</label>
                <input
                  required
                  id="last_name"
                  name="last_name"
                  type="text"
                  value={capitalizeFirstLetter(userState.last_name)}
                  onChange={handleChange}
                  size="small"
                />

                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={userState.email}
                  readOnly={readOnly}
                  onFocus={() => setReadOnly(false)}
                  onBlur={() => {
                    setReadOnly(true);
                    checkValidEmail();
                  }}
                  onChange={handleChange}
                  size="small"
                  autoComplete="email"
                />

                {emailError && (
                  <div className="birthdate-error">Invalid Email</div>
                )}

                {duplicateEmail && (
                  <div className="birthdate-error">Duplicate Email</div>
                )}

                {!editing && (
                  <>
                    <label htmlFor="password">Password</label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      value={userState.password}
                      onChange={handleChange}
                      size="small"
                      autoComplete="off"
                    />
                  </>
                )}

                <label htmlFor="phone">Phone</label>
                <input
                  id="phone"
                  name="phone"
                  type="phone"
                  value={userState.phone}
                  onChange={handleChange}
                  maxLength={13}
                  size="small"
                />

                <label>Birthdate</label>
                <input
                  max={maxValue}
                  type="date"
                  size="small"
                  value={userState.birth_date}
                  id="birth_date"
                  name="birth_date"
                  onChange={handleChange}
                />

                {!deceased && !editing && (
                  <div className="invite-user-wrapper">
                    <h2>Invite User?</h2>
                    <input
                      type="checkbox"
                      onChange={() => setInvite((i) => !i)}
                    />
                  </div>
                )}

                {!editing && (
                  <div className="deceased-check">
                    <h2>Deceased?</h2>

                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={() => {
                        setDeceased((d) => !d);
                        if (deceased && invite) {
                          setInvite((i) => !i);
                        }
                      }}
                    />
                  </div>
                )}

                {deceased && (
                  <div className="death-date-wrapper">
                    <div className="death-date">
                      <label>Deathdate</label>
                      <input
                        type="date"
                        id="death-date"
                        name="death_date"
                        onChange={(e) => setDeathDate(e.target.value)}
                      />

                      {deathDateError && deathDate && (
                        <div className="death-error">
                          Deathdate cannot be set for a future date
                        </div>
                      )}

                      {deathBirthOrderError && deathDate && (
                        <div className="death-error">
                          Deathdate cannot be set prior to birthdate
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {editing && (
                  <div className="activity-status-wrapper">
                    <label htmlFor="activity-status">Activity Status</label>
                    <select
                      name="active"
                      onChange={(event) => handleChange(event)}
                      value={userState.active}
                    >
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                )}

                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    visibility: errorMsg ? "visible" : "hidden",
                  }}
                >
                  Error. Please check your values and try again
                </div>
                <div className="user-form-buttons">
                  <button
                    className="cancel-button"
                    type="button"
                    onClick={props.history.goBack}
                  >
                    Back
                  </button>

                  <Button
                    className="button-dark"
                    type="submit"
                    isDisabled={
                      !duplicateEmail &&
                      !emailError &&
                      !isLoading &&
                      !birthDateError &&
                      !deathBirthOrderError &&
                      !deathDateError
                        ? false
                        : true
                    }
                    command={
                      isLoading ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : (
                        "Save"
                      )
                    }
                  >
                    Save
                  </Button>
                </div>

                <input type="hidden" name="user_id" value={userState.user_id} />
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
