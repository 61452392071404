import checkSVG from "../../img/square-check-solid.svg";
import xSVG from "../../img/square-xmark-solid.svg";

export default function VerifiedBadge({ verified }) {
  if (verified) {
    return (
      <div className="verified-icon verified-check-mark">
        <img src={checkSVG} alt="User verified" />
      </div>
    );
  } else {
    return (
      <div className="verified-icon verified-x-mark">
        <img src={xSVG} alt="User verified" />
      </div>
    );
  }
}
