const FamilyContact = () => {
  return (
    <div>
      <div className="family-contact-wrapper">
        <div className="family-contact-text-wrapper">
          <h1>Add Contact Form</h1>
        </div>
        <div className="form-wrapper">
          <div className="form">
            <div className="side-by-side-input-wrapper">
              <input
                placeholder="First Name*"
                style={{ marginRight: "14px" }}
              />
              <input placeholder="Last Name*" style={{ marginLeft: "14px" }} />
            </div>
            <div className="email-address-input-wrapper">
              <input placeholder="Email*" type="email" />
              <input placeholder="Address" type="text" />
            </div>
            <div className="location-input-wrapper">
              <input placeholder="City" type="text" />
              <input placeholder="State" type="text" />
              <input placeholder="Zip" type="text" />
            </div>

            <div className="contact-methods-wrapper">
              <span></span>
              <p>Contact Methods</p>
              <span></span>
            </div>
            <div className="mobile-phone-wrapper">
              <input placeholder="Mobile Phone" type="text" />
            </div>
            <div className="save-contact-button-wrapper">
              <button>Save Contact</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamilyContact;
