/* 
  Current variants incldued in the coresponding Button.scss are:
    -dark
    -light
    -outlined
    -delete
    -disabled
  The color scheme of each variant maps to the colors defined in theme.scss.  If you wish to add a new variant, simply select a class in Buttons.scss called  ................... .button-<variant-name>.

  When you want to use your button component, simply import it, and give it the variant name you used in your SCSS style.

  Props: 
    "type" is a default HTML property.  You can make it a submit, or some sort of other button. 
    "variant" is what kind of style you want the button to have.  You can create any custom variant you want in Button.scss.
    "isDisabled" will determine whether the button is disabled or not.  If disabled, it should call the .button-disabled styles from SCSS and lose all functionality.
    "handleClick" passes down whatever function you want the button to perform.  You'll define that function in the same component where you call <Button />.
    "command" is the text that goes inside the button - a call to action for the user, which also explains the function of the button.

    NOTE - the width of <Button /> is set to 100%.  If you want the button to be a certain size, I recommend wrapping it in a div, and manipulating the wrapper's dimensions.  For example, if the button is too wide, the wrapping div can be set to 50%, and <Button /> will then fill a narrower space.
 */

const Button = ({ type, variant, isDisabled, handleClick, command }) => {
  return (
    <button
      className={`button button-${variant}`}
      disabled={isDisabled}
      onClick={handleClick}
      type={type}
    >
      {command}
    </button>
  );
};

export default Button;
