import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import asyncAPICall from "../../util/apiWrapper";
import Logo from "../../img/famlinc-logo.png";
import Button from "../core/Button";

export default function MergeConfirmation(props) {
  const [mergeSuccess, setMergeSuccess] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [mergeConfirm, setMergeConfirm] = useState(false);
  const history = useHistory();

  const handleSubmit = () => {
    let mergeToken = props.match.params.mergeToken;
    setIsLoading(true);

    asyncAPICall(
      `/merge/${mergeToken}`,
      "POST",
      null,
      null,
      () => {
        setIsLoading(false);
        setMergeSuccess(true);
      },
      (err) => {
        console.log("Merge Error", err);
        setMergeSuccess(false);
        setIsLoading(false);
      }
    );
  };

  return (
    <div className="merge-confirmation-container">
      <Link to="/login">
        <img src={Logo} alt="FamLinc-logo" />
      </Link>
      {mergeConfirm ? (
        <div className="merge-confirmed">
          <div className="text-wrapper">
            <h2>{mergeSuccess ? "Merge Successful" : "Merge unsuccessful"}</h2>
          </div>
          <Link to="/login">
            <Button
              className="verify-btn"
              variant="dark"
              type="submit"
              command="Log in"
            />
          </Link>
        </div>
      ) : (
        <div className="merge-confirmation">
          <div className="text-wrapper">
            <h2>Are you sure you would like to merge the users?</h2>
          </div>
          <div className="confirm-btns-container">
            <div className="confirm-btns-wrapper">
              <button
                onClick={() => {
                  history.push("/login");
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleSubmit();
                  setMergeConfirm(true);
                }}
              >
                {isLoading ? (
                  <i className="fas fa-spinner fa-spin" />
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
