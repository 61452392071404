import { useContext, useState } from "react";
import Modal from "react-modal";

import { MeContext } from "../../context/UserContext";
import DefaultAvatar from "../../img/default-avatar.png";
import asyncAPICall from "../../util/apiWrapper";

const MergeUserForm = (props) => {
  const { me } = useContext(MeContext);
  const [mergeTokenSent, setMergeTokenSent] = useState(false);

  const handleGenerateMergeToken = () => {
    asyncAPICall(
      "/merge-request",
      "POST",
      {
        user_id_1: me.user_id,
        user_id_2: props.userData.user_id,
      },
      null,
      () => {
        setMergeTokenSent(true);
      },
      (err) => {
        console.log("Merge user error", err);
      },
      true,
      null
    );
    setTimeout(() => {
      props.setMergeModalIsOpen(false);
    }, 3000);
  };

  const renderUserToMerge = () => {
    return (
      <div className="merge-item">
        <div className="member-wrapper">
          <img src={me.avatar_link || DefaultAvatar} alt="user avatar" />
          <h1>{`${me.first_name} ${me.last_name}`}</h1>
        </div>
        <h1>
          <i className="fas fa-arrow-left"></i>
        </h1>
        <div className="member-wrapper">
          <img
            src={props.userData.avatar_link || DefaultAvatar}
            alt="user avatar"
          />
          <h1>{`${props.userData.first_name} ${props.userData.last_name}`}</h1>
        </div>
        <button onClick={handleGenerateMergeToken} disabled={mergeTokenSent}>
          <i className="fas fa-exchange-alt"></i> Merge
        </button>
      </div>
    );
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => props.setMergeModalIsOpen(false)}
      style={
        props.width >= 1367
          ? {
              overlay: {
                backgroundColor: "#707070BF",
              },
              content: {
                position: "absolute",
                top: "15%",
                bottom: "33%",
                left: "33%",
                width: "33%",
                borderRadius: "10px",
                backgroundColor: "#FAFAFA",
                paddingBottom: "100px",
              },
            }
          : props.width > 414 && props.width <= 1367
          ? {
              overlay: {
                backgroundColor: "#707070BF",
              },
              content: {
                position: "absolute",
                left: "25%",
                top: "20%",
                width: "50%",
                height: "60%",
                borderRadius: "10px",
                backgroundColor: "#FAFAFA",
              },
            }
          : props.width <= 414
          ? {
              overlay: {
                backgroundColor: "#707070BF",
              },
              content: {
                position: "absolute",
                left: "3%",
                top: "20%",
                width: "85%",
                height: "60%",
                borderRadius: "10px",
                backgroundColor: "#FAFAFA",
              },
            }
          : {}
      }
    >
      <div className="merge-form-wrapper">
        <div className="exit-button">
          <button onClick={() => props.setMergeModalIsOpen(false)}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        <h1>Merge</h1>
        <h3
          style={{ marginTop: "30px" }}
        >{`Merge in: ${props.userData.first_name} ${props.userData.last_name}? (Your information takes priority)`}</h3>
        <div className="merge-items-wrapper">
          {renderUserToMerge()}
          {mergeTokenSent && (
            <h1>An email has been sent to you with a link to confirm merge</h1>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MergeUserForm;
