import { useState } from "react";

import asyncAPICall from "../../util/apiWrapper";
import { isValidEmail } from "../../util/stringUtils";
import Logo from "../../img/FamLincLogo.png";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    asyncAPICall(
      "/user/pw_change_request",
      "POST",
      {
        email: email,
      },
      null,
      props.history.push("/login/email/sent"),
      null,
      false
    );
  };

  return (
    <div className="fp-page-wrapper">
      <div className="logo">
        <img
          style={{ cursor: "pointer" }}
          onClick={() => props.history.push("/")}
          src={Logo}
          alt="famlinc-logo"
        />
      </div>
      <div className="fp-content-container">
        <div className="fp-text-wrapper">
          <h2>Recovery Password</h2>
          <h3>Don't worry, it happens to the best of us.</h3>
        </div>

        <div className="form-wrapper">
          <div className="input-container">
            <input
              name="email"
              type="email"
              placeholder="Your email here..."
              onChange={(e) => setEmail(e.target.value)}
              className="fp-email-input"
              required
            />
          </div>
          <div className="btn-container">
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={!isValidEmail(email)}
            >
              Send Link
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
