import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import Checkbox from "../../forms/checkbox";
import Avatar from "../../../img/avatar.png";
import { MeContext } from "../../../context/UserContext";
import asyncAPICall from "../../../util/apiWrapper";
import useAbortEffect from "../../hooks/useAbortEffect";

const SearchResultItem = (props) => {
  const [displayLink, setDisplayLink] = useState(false);
  const [relationship, setRelationship] = useState("");
  const { avatar_link, first_name, last_name, user_id } = props.person;
  const { me } = useContext(MeContext);

  useAbortEffect(
    (signal) => {
      if (me.user_id !== user_id) {
        asyncAPICall(
          `/user/relationship/${me.user_id}/${user_id}`,
          "GET",
          null,
          null,
          (data) => {
            if (data) {
              setRelationship(data);
            }
          },
          (err) => {
            if (!signal.aborted) {
              console.log(err);
            }
          },
          true,
          null,
          true,
          signal
        );
      }
    },
    [user_id, me.user_id]
  );

  useEffect(() => {
    setDisplayLink(props.displayLink);
  }, [props.displayLink]);

  return (
    <div className="search-result-item-container">
      {props.displayCheckbox === true ? (
        <div className="checkbox-wrapper">
          <Checkbox
            hidden={false}
            person={props.person}
            selected={props.selected}
            setSelected={props.setSelected}
          />
        </div>
      ) : null}
      <div className="avatar-image-wrapper">
        <img src={avatar_link || Avatar} alt="search result profile avatar" />
      </div>
      <div className="search-item-info">
        <h2>
          {first_name} {last_name}
        </h2>

        {props.displayRelationship && <h1>{relationship}</h1>}
      </div>

      {displayLink && (
        <Link to={`/home/${props.person.user_id}`}>
          <i className="fas fa-arrow-circle-right"></i>
        </Link>
      )}
    </div>
  );
};

export default SearchResultItem;
