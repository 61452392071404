import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/toastNotifications.scss";

toast.configure();

export const successfulToast = (message) => {
  toast.success(
    <div>
      <i className="fas fa-check-circle"></i>
      {message}
    </div>,
    { autoClose: 3000 }
  );
};

export const errorToast = (message) => {
  toast.error(message, { autoClose: 3000 });
};
