import { useHistory } from "react-router-dom";

const NoMatch = () => {
  let history = useHistory();
  return (
    <div className="no-match-page-wrapper">
      <div className="text-wrapper">
        <h2>Hmm</h2>
        <h1>That page does not exist...</h1>
      </div>
      <div className="button-wrapper">
        <button className="dark-btn" onClick={() => history.goBack()}>
          BACK
        </button>
      </div>
    </div>
  );
};

export default NoMatch;
