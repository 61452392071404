import React from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../img/FamLincLogo.png";

export default function Timeout() {
	const history = useHistory();
	const handleClick = () => history.push("/login");
	return (
		<div className="timeout-confirmation-page-wrapper">
			<div className="logo-wrapper">
				<img src={Logo} alt="logo" />
			</div>
			<div className="timeout-text-wrapper">
				<p>
					Uh-oh. It looks like your session has been left inactive. For security
					purposes, you have been logged out.
				</p>
			</div>
			<div className="login-button-wrapper">
				<div className="login-wrapper">
					<div className="login">
						<button onClick={handleClick}>Log Back In</button>
					</div>
				</div>
			</div>
		</div>
	);
}
