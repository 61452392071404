import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import Logo from "../../img/famlinc-logo.png";
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";
import { MeContext } from "../../context/UserContext";
import useWindowSize from "../hooks/useWindowSize";

const Header = (props) => {
  const [isActive, setIsActive] = useState(false);
  const { me, setAuthToken } = useContext(MeContext);
  const { width } = useWindowSize();

  useEffect(() => {
    const auth_token_from_cookie = Cookies.get("auth_token");

    setAuthToken(auth_token_from_cookie || null);
  }, [setAuthToken]);

  const handleClick = () => {
    props.history.push(`/profile/edit/${me.user_id}`);
  };

  return (
    <div className="navbar-wrapper">
      <div className="logo-wrapper">
        <Link className="nav-item" to={`/home/${me.user_id}`}>
          <img src={Logo} alt="famlinc logo"></img>
        </Link>
      </div>

      {/* Mobile Navbar */}
      {width < 1050 && (
        <NavbarMobile
          searchTerm={props.searchTerm}
          setSearchTerm={props.setSearchTerm}
          isActive={isActive}
          setIsActive={setIsActive}
          handleClick={handleClick}
          userID={me.user_id}
        />
      )}

      {/* Desktop Navbar */}
      {width >= 1050 && (
        <NavbarDesktop
          searchTerm={props.searchTerm}
          setSearchTerm={props.setSearchTerm}
          isActive={isActive}
          setIsActive={setIsActive}
          handleClick={handleClick}
        />
      )}
    </div>
  );
};

export default Header;
