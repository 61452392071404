import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import Avatar from "../../img/avatar.png";
import SocialMediaIcons from "../core/socialMediaIcons";
import asyncAPICall from "../../util/apiWrapper";
import { MeContext } from "../../context/UserContext";

const ContactInfoProfile = (props) => {
  const { user_id } = props.match.params;
  const { me } = useContext(MeContext);
  const [user, setUser] = useState({});
  const relationship = props.location.state;

  useEffect(() => {
    asyncAPICall(
      `/user/get/${user_id}`,
      "GET",
      null,
      null,
      (data) => {
        setUser(data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [user_id]);

  return (
    <div className="content-page-wrapper">
      <div className="avatar-wrapper">
        <img src={user.avatar_link || Avatar} alt="avatar-img" />
      </div>
      <div className="form-wrapper">
        <div className="contact-name">
          <h2>{`${user.first_name} ${user.last_name} Contact Information`}</h2>
        </div>
        <div className="info-wrapper">
          <div className="paragraph-wrapper">
            <p>{`Email: ${user.email || "NA"}`}</p>
            <p>{`Address: ${user.address || "NA"}`}</p>
            <p>{`City: ${user.city || "NA"}`}</p>
            <p>{`State: ${user.state || "NA"}`}</p>
            <p>{`ZIP: ${user.zip_code || "NA"}`}</p>
            <p>{`Phone Number: ${user.phone || "NA"}`}</p>
          </div>
        </div>
        <div className="page-button-wrapper">
          <div className="back-save-buttons">
            <button
              className="back-button"
              onClick={() => props.history.goBack()}
            >
              Back
            </button>
            {me.user_id !== user.user_id ? (
              user.verified === false ? (
                <div className="edit-contact">
                  {user_id === me.user_id ||
                  user.created_by === me.user_id ||
                  (relationship && relationship !== "No Relationship found") ? (
                    <Link to={`/profile/edit/${user_id}`}>
                      <button className="edit-contact-btn">Edit Contact</button>
                    </Link>
                  ) : null}
                </div>
              ) : null
            ) : (
              <div className="edit-contact">
                {user_id === me.user_id ||
                user.created_by === me.user_id ||
                (relationship && relationship !== "No Relationship found") ? (
                  <Link to={`/profile/edit/${user_id}`}>
                    <button className="edit-contact-btn">Edit Contact</button>
                  </Link>
                ) : null}
              </div>
            )}
          </div>
          <div className="action-buttons-wrapper">
            {user_id !== me.user_id ? (
              <Link
                className="send-message-btn"
                to={{
                  pathname: "/send-message",
                  state: {
                    recipients: [`${user.first_name} ${user.last_name}`],
                    recipientsEmail: [`${user.email}`],
                    sender: me.email,
                  },
                }}
              >
                {user?.verified && (
                  <button className="button-dark">Send Message</button>
                )}
              </Link>
            ) : null}
          </div>
        </div>

        <div className="social-media-icons">
          <SocialMediaIcons {...me} />
        </div>
      </div>
    </div>
  );
};
export default ContactInfoProfile;
