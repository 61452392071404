import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";

import Carousel from "../core/carousel";
import asyncAPICall from "../../util/apiWrapper";
import CreateListForm from "../CreateListForm";
import Default from "../../img/default-list-image.png";
import { MeContext } from "../../context/UserContext";
import useWindowSize from "../hooks/useWindowSize";
import useAbortEffect from "../hooks/useAbortEffect.js";

Modal.setAppElement("#root");

const FamilyList = (props) => {
  const { me } = useContext(MeContext);
  const { width } = useWindowSize();
  const [userLists, setUserLists] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalStyle, setModalStyle] = useState({
    overlay: {
      position: "fixed",
      backgroundColor: "rgba(0, 0, 0, 0.65)",
    },
    content: {
      position: "absolute",
      top: "10%",
      left: "25%",
      right: "25%",
      bottom: "25%",
      border: "1px solid #FAFAFA",
      background: "#FAFAFA",
      overflow: "hidden",
      borderRadius: "10px",
      outline: "none",
    },
  });

  useAbortEffect(
    (signal) => {
      setModalStyle((ms) => {
        const styles =
          width <= 500
            ? {
                left: "5%",
                right: "5%",
                bottom: "10%",
              }
            : {
                left: "25%",
                right: "25%",
                bottom: "25%",
              };

        return { ...ms, content: { ...ms.content, ...styles } };
      });
    },
    [width]
  );

  useAbortEffect(
    (signal) => {
      asyncAPICall(
        `/user/lists/${me.user_id}`,
        "GET",
        null,
        null,
        (data) => {
          setUserLists(data.lists);
        },
        (err) => {
          if (!signal.aborted) {
            console.log(err);
          }
        },
        true,
        null,
        true,
        signal
      );
    },
    [me.user_id]
  );

  const renderYourLists = () => {
    if (userLists?.length) {
      return userLists.map((list) => {
        return (
          <div
            key={list.list_id}
            className="list-item-wrapper"
            style={{
              backgroundImage: list.list_image
                ? `url(${list.list_image})`
                : `url(${Default})`,
              backgroundSize: "cover",
              backgroundPosition: "left",
            }}
          >
            <Link to={`/list/${list.list_id}`}>
              <div className="family-list-item">
                <h1>{list.list_name}</h1>
              </div>
            </Link>
          </div>
        );
      });
    } else {
      return (
        <h1
          style={{
            fontSize: "16px",
          }}
        >
          You have not created any lists.
        </h1>
      );
    }
  };

  const renderStandardLists = () => {
    const listTitles = [
      { name: "Parents", id: 1 },
      { name: "Children", id: 2 },
      { name: "Siblings", id: 3 },
      { name: "Grandparents", id: 4 },
      { name: "Aunts and Uncles", id: 5 },
      { name: "Cousins", id: 6 },
    ];
    return listTitles.map((item) => {
      return (
        <div key={item.id} className="list-item-wrapper">
          <Link to={`quick-list/${item.name}`}>
            <div className="family-list-item">
              <h1>{item.name}</h1>
            </div>
          </Link>
        </div>
      );
    });
  };

  return (
    <div className="family-list-content-wrapper">
      <div className="title">
        <h1>Family Lists</h1>
      </div>
      <div className="list-wrapper">
        <div className="create-btn">
          <h3>Quick Lists</h3>
          <div className="create-list-button">
            <button onClick={() => setModalIsOpen(true)}>+ Create List</button>
            <div>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Inline Styles Modal"
                style={modalStyle}
              >
                <div className="close-modal exit-button">
                  <button onClick={() => setModalIsOpen(false)}>
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <div className="align-form">
                  <CreateListForm
                    setUserLists={setUserLists}
                    setModalIsOpen={setModalIsOpen}
                    user_id={me.user_id}
                  />
                </div>
              </Modal>
            </div>
          </div>
        </div>
        <div className="mobile-lists">
          <h3 className="custom-lists-title">Custom Lists</h3>
          {renderYourLists()}
        </div>
        <div className="desktop-lists">
          <Carousel
            imageSource={renderStandardLists()}
            scrollDistance={800}
            arrowColor={"black"}
          />
          <Carousel
            imageSource={renderYourLists()}
            scrollDistance={800}
            arrowColor={"black"}
            headerText={"Custom Lists"}
          />
        </div>
      </div>
    </div>
  );
};

export default FamilyList;
