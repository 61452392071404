import { forwardRef } from "react";

const FileUpload = forwardRef((props, ref) => {
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const b64Result = event.target.result;

        if (props.imgSize) {
          const { height, width } = props.imgSize;
          const image = document.createElement("img");
          image.src = b64Result;

          image.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.height = height;
            canvas.width = width;
            const canvasContext = canvas.getContext("2d", { alpha: false });

            canvasContext.drawImage(image, 0, 0, canvas.width, canvas.height);
            const canvasData = canvas.toDataURL(file.type);

            resolve(canvasData);
          };
        } else {
          resolve(b64Result);
        }
      };
      reader.onerror = (error) => reject(error);
    });

  const handleChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const file_name = file.name.slice(0, file.name.lastIndexOf("."));
      const ext = file.name.split(".")[file.name.split(".").length - 1];

      await toBase64(file).then((result) => {
        props.handleUpload({ result, file_name, file, ext });
      });
    }
  };

  return (
    <input type="file" ref={ref} {...props.domProps} onChange={handleChange} />
  );
});

FileUpload.defaultProps = {
  handleUpload: () => console.warn("No Upload Action Defined"),
};

export default FileUpload;
