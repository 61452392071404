import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Button from "@material-ui/core/Button";

import ActiveBadge from "../core/activeBadge.js";
import VerifiedBadge from "../core/verifiedBadge.js";
import { formatPhone } from "../../util/stringUtils";
import SecurityWrapper from "../../util/securityWrapper";
import asyncAPICall from "../../util/apiWrapper";
import DeleteUserModal from "../modals/DeleteUserModal.js";
import { MeContext } from "../../context/UserContext.js";

export default function UserList(props) {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const link_to_add_user = "/admin/user-add/";
  const { me } = useContext(MeContext);

  const columns = {
    first_name: {
      name: "First Name",
      selector: "first_name",
      sortable: true,
      cell: (row) => (
        <Link className="table-link" to={{ pathname: `/home/${row.user_id}` }}>
          {row.first_name}
        </Link>
      ),
    },
    last_name: {
      name: "Last Name",
      selector: "last_name",
      sortable: true,
      cell: (row) => (
        <Link className="table-link" to={{ pathname: `/home/${row.user_id}` }}>
          {row.last_name}
        </Link>
      ),
    },
    email: {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    phone: {
      name: "Phone",
      selector: "phone",
      sortable: true,
      cell: (row) => formatPhone(row.phone),
    },
    role: {
      name: "Role",
      selector: "role",
      sortable: true,
    },
    active: {
      name: "Active",
      selector: "active",
      sortable: true,
      cell: (row) => <ActiveBadge active={row.active} />,
      width: "150px",
    },
    verified: {
      name: "Verified",
      selector: "verified",
      sortable: true,
      cell: (row) => <VerifiedBadge verified={row.verified} />,
    },
    edit_button: {
      name: "",
      sortable: false,
      cell: (row) => (
        <Link to={{ pathname: `user/edit/${row.user_id}` }}>
          <Button
            style={{
              display:
                me.role === "admin" && row.role === "super-admin"
                  ? "none"
                  : "block",
            }}
            className="button-dark"
          >
            Edit
          </Button>
        </Link>
      ),
      width: "150px",
    },
    delete_button: {
      name: "",
      cell: (row) =>
        me.role === "super-admin" && row.user_id !== me.user_id ? (
          <DeleteUserModal setFilteredList={setFilteredList} user={row} />
        ) : me.role === "admin" && row.role === "user" ? (
          !row.verified ? (
            <DeleteUserModal setFilteredList={setFilteredList} user={row} />
          ) : null
        ) : null,
    },
    user_id: {
      name: "ID",
      selector: "user_id",
      sortable: false,
    },
  };

  if (selectedColumns.length === 0) {
    setSelectedColumns([
      columns.first_name,
      columns.last_name,
      columns.email,
      columns.phone,
      columns.role,
      columns.active,
      columns.verified,
      columns.edit_button,
      columns.delete_button,
    ]);
  }

  useEffect(() => {
    asyncAPICall(
      "/user/get",
      "GET",
      null,
      null,
      (data) => {
        setFilteredList(data);
      },
      null
    );
  }, [props]);

  return (
    <div className="list-wrapper list-page">
      <div className="admin-title-wrapper">
        <h1>Admin</h1>
      </div>
      <div className="button-and-search">
        <SecurityWrapper restrict_roles="user">
          {!props.showAddButton || props.showAddButton === false ? (
            <Button
              disabled={props.disableAddUser}
              onClick={() => props.history.push(link_to_add_user)}
              className="button-dark"
            >
              <i className="fas fa-plus button-icon"></i>Add New User
            </Button>
          ) : (
            <div />
          )}
        </SecurityWrapper>
        <SecurityWrapper roles="user">
          <div />
        </SecurityWrapper>
        {/* {!props.showFilter || props.showFilter === false ? (
          <TextField
            id="search"
            type="text"
            placeholder="Filter results..."
            value={filterText}
            onChange={handleFilter}
            variant="outlined"
            size="small"
          />
        ) : ( */}
        <div />
      </div>
      <div className="seperator"></div>
      <div className="table-wrapper">
        <DataTable
          title={
            <span>
              <i className="fas fa-user"></i> Users
            </span>
          }
          columns={selectedColumns}
          data={filteredList}
        />
      </div>
    </div>
  );
}
