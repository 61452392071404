import { useState, useRef } from "react";

import asyncAPICall from "../util/apiWrapper";
// import AddUserToList from "./addUserToList";
import FileUpload from "./core/FileUpload";
import Button from "./core/Button";

function CreateListForm(props) {
  const { user_id, setModalIsOpen, setUserLists } = props;
  const imageRef = useRef();
  const [name, setName] = useState("");
  const [image, setImage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsLoading(true);

    const list_image_bytes = image.result;

    const body = {
      list_name: name,
      list_image_bytes,
    };

    if (props.users) body.users = props.users.map((user) => user.user_id);

    asyncAPICall(
      `/list/add/${user_id}`,
      "POST",
      body,
      null,
      (data) => {
        if (props.setUserLists) setUserLists((l) => [...l, data]);
        setModalIsOpen(false);
      },
      (err) => {
        setErrorMessage("Something went wrong, please try again.");
        console.error("Create List Error: ", err);
      }
    );
  };

  const handleUpload = (data) => {
    setImage(data);
  };

  return (
    <div className="create-list-form">
      <div className="custom-name">
        <h1>Custom Family List</h1>
      </div>
      <div className="form-field">
        <form onSubmit={handleSubmit}>
          <div className="text-box">
            <input
              name="name"
              type="text"
              placeholder="  Enter List Name"
              required
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="banner-image">
            <h1>Banner Image</h1>
          </div>
          <div className="file-upload-banner-image">
            <FileUpload
              ref={imageRef}
              domProps={{
                className: "image-upload",
                accept: ".img, .jpeg, .jpg, .png",
              }}
              handleUpload={handleUpload}
              multiple={false}
              name="banner"
            />
          </div>
          {props.users?.length > 0 && (
            <div>
              <b>List Members:</b>
              <div>
                {props.users.map((user) => (
                  <div key={user.user_id}>
                    {user.first_name} {user.last_name}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="save-button">
            <Button
              type="submit"
              isDisabled={isLoading}
              command={
                isLoading ? <i className="fas fa-spinner fa-spin" /> : "Save"
              }
            >
              Save
            </Button>
          </div>
        </form>
      </div>
      <p style={{ color: "firebrick" }}>{errorMessage}</p>
    </div>
  );
}

export default CreateListForm;
