import { useContext } from "react";
import { useClickOutside } from "../hooks/useClickOutside";
import DropdownItem from "./DropdownItem";
import { MeContext } from "../../context/UserContext";

const DropdownMenu = ({ userID, setDropdownOpen }) => {
  const { logout } = useContext(MeContext);
  const dropdownRef = useClickOutside(() => {
    setDropdownOpen(false);
  });

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="menu">
        <DropdownItem goTo={`/profile/edit/${userID}`}>
          Edit Profile
        </DropdownItem>

        <DropdownItem goTo={`/activity/${userID}`}>
          Activity History
        </DropdownItem>

        <DropdownItem goTo={`/manage-csv/${userID}`}>Manage Files</DropdownItem>

        <DropdownItem overrideClick={logout} goTo="/login">
          Sign Out
        </DropdownItem>
      </div>
    </div>
  );
};

export default DropdownMenu;
