import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import Logo from "../../img/famlinc-logo.png";
import asyncAPICall from "../../util/apiWrapper";

const ChangePassword = (props) => {
  const resetlink = props.match.params.resetlink;
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [validForm, setValidForm] = useState(false);
  const [token, setToken] = useState("");
  const [userID, setUserID] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  const decodeValues = useCallback(() => {
    try {
      const decodedString = window.atob(resetlink);
      const userIDString = decodedString?.split("|")[0].split("=")[1];
      const tokenString = decodedString?.split("|")[2].split("=")[1];

      setUserID(userIDString);
      setToken(tokenString);
    } catch (err) {
      console.error("Decode token err: ", err.message);
      setToken("invalid");
    }
  }, [resetlink]);

  useEffect(() => {
    checkFormValidity();
  });

  useEffect(() => {
    decodeValues();
  }, [decodeValues]);

  const checkFormValidity = () => {
    if (
      newPassword &&
      confirmNewPassword &&
      newPassword === confirmNewPassword
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage(false);
    asyncAPICall(
      "/user/forgot_password_change",
      "POST",
      {
        new_password: confirmNewPassword,
        user_id: userID,
        token: token,
      },
      null,
      (data) => {
        props.history.push("/login");
      },
      (err) => {
        setErrorMessage(true);
      },
      false
    );
  };

  if (token === "invalid") {
    return (
      <div className="password-change-page-wrapper">
        <h2>Invalid Token. Click below to recover your password.</h2>

        <form className="change-form">
          <button
            className="button-dark change-password-button"
            type="button"
            onClick={() => props.history.push("/login/password/recovery")}
          >
            Request New Password
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="password-change-page-wrapper">
      <div className="pc-logo">
        <Link to="/login">
          <img src={Logo} alt="" height="32px"></img>
        </Link>
      </div>

      <h2>Password Reset</h2>
      <form className="change-form">
        <input
          required
          className="new-password top-password"
          type="password"
          placeholder="New Password *"
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          required
          className="new-password"
          type="password"
          placeholder="Confirm New Password *"
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
        <h1 className="password-error">
          {newPassword === confirmNewPassword ? "" : "Password does not match"}
        </h1>
        <button
          className="button-dark change-password-button"
          type="submit"
          onClick={handleSubmit}
          disabled={!validForm ? "disabled" : ""}
        >
          Change Password
        </button>
        <h3 style={{ color: "firebrick" }}>
          {errorMessage && "Could Not Submit. Try again."}
        </h3>
      </form>
    </div>
  );
};

export default ChangePassword;
