import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";

import asyncAPICall from "../../util/apiWrapper";
import SearchResultItem from "./search/searchResultItem";
import { MeContext } from "../../context/UserContext";
import { errorToast } from "../../util/toastNotifications";

Modal.setAppElement("#root");

const QuickLists = (props) => {
  const { list_type } = props.match.params;
  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selected, setSelected] = useState([]);
  const [list, setList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { me } = useContext(MeContext);

  useEffect(() => {
    setIsLoading(true);
    let list_url;
    if (list_type === "Aunts and Uncles") {
      list_url = list_type.replaceAll(" ", "_").toLowerCase();
    } else {
      list_url = list_type.toLowerCase();
    }
    asyncAPICall(
      `/quick-list/${list_url}`,
      "GET",
      null,
      null,
      (data) => {
        if (data) {
          setList(Object.values(data)[0]);
          setSearchResults(Object.values(data)[0]);
        }
        setIsLoading(false);
      },
      (err) => {
        console.log(err);
        setIsLoading(false);
      },
      true,
      null,
      false
    );
  }, [list_type]);

  useEffect(() => {
    if (searchResults.length > 0 && search) {
      setSearchResults((prev) =>
        prev.filter(
          (person) =>
            person.first_name?.toLowerCase().includes(search) ||
            person.last_name?.toLowerCase().includes(search)
        )
      );
    }
  }, [search, searchResults.length]);

  const handleSelectAll = () => {
    const stateUsers = [...list];

    stateUsers.forEach((user) => {
      if (selectAll) {
        user.isChecked = false;
        setSelected([]);
      } else {
        user.isChecked = true;
        const addUsers = stateUsers;
        setSelected(addUsers);
      }
    });

    setSelectAll((a) => !a);
  };

  useEffect(() => {
    if (list.length > 0) {
      list.forEach((user) => {
        user.isChecked = false;
      });
    }
  }, [list]);

  const checkListType = (type) => {
    if (type === "Siblings") {
      if (list.length > 0) {
        let newList = [];
        list.forEach((e) => {
          if (Object.values(e)[0].length > 0) {
            newList.push(...Object.values(e)[0]);
          }
        });
        return newList;
      }
    } else {
      return list;
    }
  };

  const renderListMembers = (list) => {
    if (searching && search === "") {
      setSearching(false);
    }

    if (list?.length > 0) {
      const memberList = searching ? [...searchResults] : [...list];

      for (let i = 0; i < list.length; i++) {
        if (!searchResults.includes(list[i])) {
          searchResults.push(list[i]);
        }
      }

      if (memberList.length === 0) {
        return <h1>No list members found.</h1>;
      }

      return memberList.map((person) => {
        if (person.user_id) {
          return (
            <SearchResultItem
              key={person.user_id}
              person={person}
              searchTerm={search}
              selected={selected}
              setSelected={setSelected}
              displayCheckbox={true}
              displayLink={true}
            />
          );
        } else {
          return null;
        }
      });
    } else {
      return <h1>No list members found.</h1>;
    }
  };

  const renderUsersNames = () => {
    if (selected) {
      return selected.map((user) => {
        return [` ${user.first_name} ${user.last_name}`];
      });
    }
  };

  const renderUsersEmails = () => {
    const userEmailsArray = [];

    if (selected) {
      selected.forEach((user) => {
        userEmailsArray.push([user.email]);
      });

      return userEmailsArray;
    }
  };

  return (
    <div className="single-list-page-wrapper">
      <div className="content-wrapper">
        <div className="single-list-title">
          <h1>{list_type}</h1>
        </div>
        <div className="searchbar">
          <input
            type="text"
            placeholder="SEARCH"
            onChange={(e) => {
              setSearch(e.target.value);
              setSearching(true);
            }}
          />
        </div>
        <div className="select-all-wrapper">
          <input
            type="checkbox"
            id="select-all-checkbox"
            onClick={handleSelectAll}
            disabled={list.length > 0 ? false : true}
          />{" "}
          <h2>Select All</h2>
        </div>
        <div className="list-members-wrapper">
          {isLoading ? (
            <i className="fas fa-spinner fa-spin" />
          ) : (
            renderListMembers(checkListType(list_type))
          )}
        </div>
        <div className="buttons-wrapper">
          <div className="send-remove-buttons">
            {selected.filter((person) => !person.verified).length > 0 ? (
              <button
                disabled={selected.length > 0 ? false : true}
                className="selected-btn"
                onClick={() =>
                  errorToast("Cannot send messages to unverified persons")
                }
              >
                Send Message to Selected
              </button>
            ) : (
              <Link
                className="send-message-btn"
                to={{
                  pathname: "/send-message",
                  state: {
                    recipients: renderUsersNames(),
                    recipientsEmail: renderUsersEmails(),
                    sender: me.email,
                  },
                }}
              >
                <button disabled={selected.length > 0 ? false : true}>
                  Send Message
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickLists;
