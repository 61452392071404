import { useState, useEffect, createContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import asyncAPICall, { awaitAPICall } from "../util/apiWrapper";

export const MeContext = createContext();

export default function UserContext(props) {
  const [me, setMe] = useState({});
  const [authToken, setAuthToken] = useState(Cookies.get("auth_token"));
  const [authIsLoading, setAuthIsLoading] = useState(true);
  const history = useHistory();

  const logout = useCallback(() => {
    setAuthIsLoading(true);
    awaitAPICall(
      `/user/logout`,
      "PUT",
      null,
      (res) => {
        if (res.status === 200) {
          Cookies.remove("auth_token");
          Cookies.remove("user_role");
          Cookies.remove("user_name");
          Cookies.remove("auth_expires");
          Cookies.remove("user_id");
          setMe({});
          setAuthToken(null);
        }
        setAuthIsLoading(false);
      },
      null,
      (error) => {
        setAuthIsLoading(false);
        console.error("Logout: ", error);
      },
      true
    );
  }, []);

  useEffect(() => {
    const authTokenFromCookie = Cookies.get("auth_token");
    const expiration = Cookies.get("auth_expires");
    const isExpired = Date.parse(expiration) < Date.now();

    if (!authTokenFromCookie) {
      logout();
    } else if (isExpired) {
      history.push("/session-timeout");
    } else {
      setAuthToken(authTokenFromCookie);
    }
  }, [logout, history]);

  useEffect(() => {
    asyncAPICall(
      "/user/get/me",
      "GET",
      null,
      null,
      (data) => {
        if (data) {
          setMe(data);
          setAuthIsLoading(false);
        } else {
          throw new Error("Could not get user information");
        }
      },
      (err) => {
        console.error("error while getting me: ", err.message);
        setAuthIsLoading(false);
        logout();
      }
    );
  }, [logout, authToken]);

  const userState = {
    me,
    setMe,
    authIsLoading,
    setAuthIsLoading,
    authToken,
    setAuthToken,
    logout,
  };

  return (
    <MeContext.Provider value={userState}>{props.children}</MeContext.Provider>
  );
}
