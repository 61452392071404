import { useState, useRef, useContext, useEffect } from "react";

import FileUpload from "../core/FileUpload";
import { MeContext } from "../../context/UserContext";
import useAbortEffect from "../hooks/useAbortEffect";

import asyncAPICall from "../../util/apiWrapper";
import { errorToast } from "../../util/toastNotifications";

const ManageCSV = () => {
  const [headers, setHeaders] = useState([]);
  const [toggleSort, setToggleSort] = useState(true);
  const [data, setData] = useState([]);
  const [bit64, setBit64] = useState("");
  const { me, setMe } = useContext(MeContext);
  const fileRef = useRef();
  const hoverRef = useRef();

  useAbortEffect(
    (signal) => {
      const sendBit64 = () => {
        asyncAPICall(
          `/csv/add`,
          "POST",
          {
            file_bytes: bit64,
          },
          null,
          (csvData) => {
            setMe((m) => {
              return { ...m, csv_link: csvData.csv_link };
            });
            setBit64("");
          },
          (err) => {
            if (!signal.aborted) {
              console.error("SUBMIT CSV ERROR: ", err);
            }
          },
          true,
          null,
          false,
          signal
        );
      };

      if (bit64) {
        sendBit64();
      }
    },
    [bit64, setMe]
  );

  useAbortEffect(
    (signal) => {
      if (me.csv_link) {
        fetch(me.csv_link, { signal })
          .then((res) => res.text())
          .then((data) => {
            processData(data);
          });
      }
    },
    [me.csv_link]
  );

  const renderFamilyMembers = () => {
    if (data?.length) {
      return data.map((member, index) => {
        return (
          <tr key={index}>
            {Object.keys(member).map((familyMember) => {
              return <td key={familyMember}>{member[familyMember]}</td>;
            })}
          </tr>
        );
      });
    }
  };

  const handleSort = (header) => {
    if (toggleSort) {
      data.sort((a, b) => {
        return a[header] < b[header] && -1;
      });
    } else {
      data.sort((a, b) => {
        return a[header] > b[header] && -1;
      });
    }
    setData(data);
    setToggleSort((t) => !t);
  };

  const handleEnter = (e) => {
    hoverRef.current = null;
    hoverRef.current = e.target;

    if (hoverRef.current.children[0]) {
      hoverRef.current.children[0].style.display = "inline-flex";
    }
  };

  const handleLeave = () => {
    hoverRef.current.children[0].style.display = "none";
    hoverRef.current = null;
  };

  const renderHeaderFields = () => {
    return headers.map((header) => {
      return header.toLowerCase().includes("name") ? (
        <td
          key={header}
          className="table-title"
          id={header}
          onClick={() => handleSort(header)}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
        >
          {header}
          <i
            id={header}
            className={`fas fa-sort-${toggleSort ? "up" : "down"}`}
          />
        </td>
      ) : (
        <td key={header} className="table-title">
          {header}
        </td>
      );
    });
  };

  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    setData(list);
    setHeaders(headers);
  };

  const handleFileUpload = ({ file, result }) => {
    if (file.type !== "text/csv") {
      errorToast("Incorrect file type. Your file must be a CSV file.");
      fileRef.current.value = null;
    } else if (result === "data:text/csv;base64,") {
      errorToast("Cannot upload an empty CSV file.");
      fileRef.current.value = null;
    } else {
      setBit64(result);
    }
  };

  const handleFileDelete = () => {
    asyncAPICall(
      `/csv/delete`,
      "DELETE",
      null,
      () => {
        setData([]);
        setHeaders([]);
        setBit64("");
      },
      null,
      (err) => {
        console.error("DELETE CSV ERROR: ", err);
      }
    );
  };

  return (
    <div className="all-family-members-page-wrapper">
      <div className="title-button-wrapper">
        <div className="page-title">
          <h1>CSV Upload Manager</h1>
        </div>
        <div className="general-list-buttons">
          <FileUpload
            domProps={{
              className: `image-upload ${
                data.length > 0 && "label-replace-csv-file"
              }`,
              title: "",
              accept: ".csv",
            }}
            handleUpload={handleFileUpload}
            ref={fileRef}
          />

          {data.length > 0 && (
            <button className="remove-csv-button" onClick={handleFileDelete}>
              Remove CSV File
            </button>
          )}
        </div>
      </div>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr style={{ padding: "5px" }}>{renderHeaderFields()}</tr>
          </thead>
          <tbody>{renderFamilyMembers()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageCSV;
