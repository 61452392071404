import { Route } from "react-router-dom";

import ForgotPassword from "./forgot-password/forgotPasswordPage";
import EmailSent from "./forgot-password/emailSentPage";
import ChangePassword from "./forgot-password/changePassword";
import LoginPage from "./pages/auth/loginPage";
import InvitePage from "./pages/invitePage";

export default function loginContainer() {
  return (
    <div className="wrapper">
      <Route
        exact
        path="/login"
        render={(routeProps) => <LoginPage {...routeProps} />}
      />
      <Route path="/login/confirm-invite/:encoded" component={InvitePage} />
      <Route path="/login/password/recovery" component={ForgotPassword} />
      <Route
        path="/login/password/change/:resetlink"
        component={ChangePassword}
      />
      <Route path="/login/email/sent" component={EmailSent} />
    </div>
  );
}
