import { useReducer, useRef, useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Avatar from "../../img/avatar.png";
import Banner from "../../img/profile-banner.jpg";
import SocialMediaIcons from "../core/socialMediaIcons";
import asyncAPICall from "../../util/apiWrapper";
import FileUpload from "../core/FileUpload";
import { isValidPhoneNumber } from "../../util/stringUtils";
import { isValidSocail } from "../../util/stringUtils.js";

const initialState = {
  avatar: {
    current: "",
    pic_bytes: "",
    file_type: "avatar",
  },
  banner: {
    current: "",
    pic_bytes: "",
    file_type: "banner",
  },
};

const imgReducer = (state, action) => {
  switch (action.type) {
    case "avatar":
      return { ...state, avatar: action.payload };
    case "banner":
      return { ...state, banner: action.payload };
    case "setDefault":
      return { ...initialState };
    default:
      return state;
  }
};

const ContactInfoProfileForm = (props) => {
  const userId = props.match.params.user_id;
  const [user, setUser] = useState({});

  const avatarRef = useRef();
  const bannerRef = useRef();

  const [successMessage, setSuccessMessage] = useState(false);
  const [failMessage, setFailMessage] = useState(false);
  const [state, dispatch] = useReducer(imgReducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [twitter, setTwitter] = useState("");
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const [error, setError] = useState(false);
  let {
    bio,
    first_name,
    last_name,
    email,
    address,
    city,
    zip_code,
    phone,
    user_id,
  } = user;

  useEffect(() => {
    let timer;
    if (successMessage) {
      timer = setTimeout(() => {
        setSuccessMessage(false);
      }, 2200);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    const getPromise = new Promise((res, rej) => {
      asyncAPICall(
        `/user/get/${userId}`,
        "GET",
        null,
        null,
        (data) => {
          if (data.banner_image)
            dispatch({
              type: "banner",
              payload: { current: data.banner_image },
            });
          if (data.avatar_link)
            dispatch({
              type: "avatar",
              payload: { current: data.avatar_link },
            });

          setFacebook(data.facebook === null ? "" : data.facebook);
          setInstagram(data.instagram === null ? "" : data.instagram);
          setLinkedin(data.linkedin === null ? "" : data.linkedin);
          setTwitter(data.twitter === null ? "" : data.twitter);

          setUser(data);
          res(data.created_by);
        },
        (err) => {
          rej(err);
          console.log("User fetch error", err);
        },
        true,
        null
      );
    });

    getPromise.then(() => {});
  }, [user.user_id, userId, props.history, setUser]);

  useEffect(() => {
    if (!phone) {
      setFailMessage(false);
    } else if (!isValidPhoneNumber(phone)) {
      setFailMessage(true);
    } else {
      setFailMessage(false);
    }
  }, [phone]);

  const handleImageUpload = (data, type) => {
    const { result } = data;

    dispatch({
      type,
      payload: { file_type: type, pic_bytes: result },
    });
  };

  const saveSocialLink = (type) => {
    let fixedUrl = "";
    let body = { user_id: userId };
    switch (type) {
      case "facebook":
        if (
          facebook.includes("https://") ||
          facebook.includes("http://") ||
          facebook === ""
        ) {
          if (isValidSocail(facebook) || facebook === "") {
            body.facebook = facebook;
          } else {
            setError(true);
          }
        } else if (facebook !== "") {
          if (isValidSocail(facebook)) {
            fixedUrl = "https://" + facebook;
            body.facebook = fixedUrl;
          } else {
            setError(true);
          }
        } else {
          setError(true);
        }
        break;
      case "instagram":
        if (
          instagram.includes("https://") ||
          instagram.includes("http://" || instagram === "")
        ) {
          if (isValidSocail(instagram) || instagram === "") {
            body.instagram = instagram;
          } else {
            setError(true);
          }
        } else if (instagram !== "") {
          if (isValidSocail(instagram)) {
            fixedUrl = "https://" + instagram;
            body.instagram = fixedUrl;
          } else {
            setError(true);
          }
        } else {
          setError(true);
        }
        break;
      case "linkedin":
        if (
          linkedin.includes("https://") ||
          linkedin.includes("http://") ||
          linkedin === ""
        ) {
          if (isValidSocail(linkedin) || linkedin === "") {
            body.linkedin = linkedin;
          } else {
            setError(true);
          }
        } else if (linkedin !== "") {
          if (isValidSocail(linkedin)) {
            fixedUrl = "https://" + linkedin;
            body.linkedin = fixedUrl;
          } else {
            setError(true);
          }
        } else {
          setError(true);
        }
        break;
      case "twitter":
        if (
          twitter.includes("https://") ||
          twitter.includes("http://") ||
          twitter === ""
        ) {
          if (isValidSocail(twitter) || twitter === "") {
            body.twitter = twitter;
          } else {
            setError(true);
          }
        } else if (twitter !== "") {
          if (isValidSocail(twitter)) {
            fixedUrl = "https://" + twitter;
            body.twitter = fixedUrl;
          } else {
            setError(true);
          }
        } else {
          setError(true);
        }
        break;
      default:
        console.error("No Social Media Link Validated...");
        return;
    }
    if (!error) {
      asyncAPICall(
        "/user/update",
        "POST",
        body,
        null,
        null,
        (err) => {
          console.log(err);
        },
        true
      );
      setUpdateSuccessful(true);
      setTimeout(() => {
        setUpdateSuccessful(false);
        setError(false);
      }, 3000);
    }

    setUpdateSuccessful(true);
    setTimeout(() => {
      setUpdateSuccessful(false);
    }, 3000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { avatar, banner } = state;

    let pic_bytes_banner = banner.pic_bytes;
    let pic_bytes_avatar = avatar.pic_bytes;

    const body = JSON.stringify({
      user_id,
      first_name,
      last_name,
      email,
      phone,
      address,
      city,
      state: user.state,
      zip_code,
      bio,
      pic_bytes_avatar,
      pic_bytes_banner,
    });

    asyncAPICall(
      "/user/update",
      "POST",
      body,
      null,
      (data) => {
        [avatarRef, bannerRef].forEach((ref) => (ref.current.value = ""));

        setUser(data);

        if (data.banner_image)
          dispatch({
            type: "banner",
            payload: { pic_bytes_banner: data.banner_image },
          });
        if (data.avatar_link)
          dispatch({
            type: "avatar",
            payload: { pic_bytes_avatar: data.avatar_link },
          });

        if (data) {
          setSuccessMessage(true);
          setFailMessage(false);
          setIsLoading(false);
        }
      },
      (err) => console.error("Update User Error: ", err),
      true,
      null,
      true
    );
  };

  const handleRemoveImage = (type) => {
    asyncAPICall(
      "/user/update",
      "POST",
      {
        user_id: user.user_id,
        [`pic_bytes_${type}`]: "",
      },
      null,
      (data) => {
        setUser(data);
        dispatch({
          type,
          payload: {
            ["pic_bytes_" + type]: { current: "", pic_bytes: "" },
          },
        });

        setSuccessMessage(true);
        setFailMessage(false);
      },
      (err) => console.error("Update User Error: ", err),
      true,
      null
    );
  };

  return (
    <div className="edit-profile-page-wrapper">
      <div
        className="banner"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "left",
          backgroundImage: `url(${
            state.banner.pic_bytes || user.banner_image || Banner
          }`,
        }}
      >
        <div
          className="avatar-wrapper"
          style={{
            backgroundImage: `url(${
              state.avatar.pic_bytes || user.avatar_link || Avatar
            }`,
            backgroundSize: "cover",
          }}
        ></div>
      </div>

      <div className="content-edit-wrapper">
        <div className="image-uploader-wrapper">
          <div>
            <h1>Avatar Image:</h1>
            <FileUpload
              domProps={{
                multiple: false,
                name: "avatar",
                className: "image-upload",
                accept: ".jpeg, .jpg, .png",
              }}
              ref={avatarRef}
              handleUpload={(results) => handleImageUpload(results, "avatar")}
              imgSize={{
                width: "265",
                height: "265",
              }}
            />

            <button
              style={{
                visibility: "visible",
              }}
              className="remove-button"
              onClick={() => handleRemoveImage("avatar")}
            >
              Remove Avatar
            </button>
          </div>

          <div id="banner-upload">
            <h1>Banner Image:</h1>
            <FileUpload
              domProps={{
                multiple: false,
                name: "banner",
                className: "image-upload",
                accept: ".jpeg, .jpg, .png",
              }}
              ref={bannerRef}
              handleUpload={(results) => handleImageUpload(results, "banner")}
              imgSize={{
                width: "1584",
                height: "396",
              }}
            />

            <button
              style={{
                visibility: "visible",
              }}
              className="remove-button"
              onClick={() => handleRemoveImage("banner")}
            >
              Remove Banner
            </button>
          </div>
        </div>

        <form className="contact-form-wrapper" onSubmit={handleSubmit}>
          <div className="contact-form-wrapper">
            <div className="title">
              <h2>Profile Info</h2>
            </div>

            <textarea
              className="bio"
              id="bio"
              cols="30"
              rows="10"
              maxLength="500"
              placeholder={bio || "Bio"}
              onChange={(e) =>
                setUser((user) => ({ ...user, bio: e.target.value }))
              }
              value={bio || ""}
            ></textarea>
            <div className="names-wrapper">
              <input
                className="name-input"
                name="first name"
                type="text"
                onChange={(e) =>
                  setUser((user) => ({ ...user, first_name: e.target.value }))
                }
                value={first_name || ""}
                placeholder={first_name || "First Name"}
                required
              />

              <input
                className="name-input"
                id="last-name"
                name="last name"
                type="text"
                onChange={(e) =>
                  setUser((user) => ({ ...user, last_name: e.target.value }))
                }
                value={last_name || ""}
                placeholder={last_name || "Last Name"}
                width="10px"
                required
              />
            </div>

            <div className="info-wrapper">
              <div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder={email || "Email"}
                  onChange={(e) =>
                    setUser((user) => ({ ...user, email: e.target.value }))
                  }
                  value={email || ""}
                  autoComplete="email"
                />
                <input
                  id="address"
                  name="address"
                  type="text"
                  onChange={(e) =>
                    setUser((user) => ({ ...user, address: e.target.value }))
                  }
                  value={address || ""}
                  placeholder={address || "Street"}
                />
              </div>

              <div className="small-address-fields">
                <input
                  id="city"
                  name="city"
                  type="text"
                  onChange={(e) =>
                    setUser((user) => ({ ...user, city: e.target.value }))
                  }
                  value={city || ""}
                  placeholder={city || "City"}
                />
                <input
                  id="state"
                  name="state"
                  type="text"
                  onChange={(e) =>
                    setUser((user) => ({ ...user, state: e.target.value }))
                  }
                  value={user.state || ""}
                  placeholder={user.state || "State"}
                />
                <input
                  id="zip_code"
                  name="zip_code"
                  type="text"
                  onChange={(e) =>
                    setUser((user) => ({ ...user, zip_code: e.target.value }))
                  }
                  value={user.zip_code || ""}
                  placeholder={user.zip_code || "Zip Code"}
                />
              </div>
            </div>
          </div>
          <div className="contact-methods-wrapper">
            <span></span>
            <h2>Contact Methods</h2>
            <span></span>
          </div>
          <div className="phone-number-wrapper">
            <input
              id="phone-number"
              name="phone number"
              type="phone"
              maxLength={13}
              onChange={(e) =>
                setUser((user) => ({ ...user, phone: e.target.value }))
              }
              value={phone || ""}
              placeholder={phone || "Phone Number"}
            />
            {failMessage ? (
              <div className="fail-message-active">
                Phone number should be 10 digits long.
              </div>
            ) : (
              <div className="fail-message-inactive">
                Phone number should be 10 digits. This message should be
                inactive
              </div>
            )}
          </div>

          <div className="action-buttons-wrapper">
            <button
              className="send-message-btn"
              type="submit"
              disabled={failMessage}
              isdisabled={isLoading.toString()}
              command={
                isLoading ? (
                  <i className="fas fa-spinner fa-spin" />
                ) : (
                  "Save Changes"
                )
              }
            >
              Save Changes
            </button>
          </div>
          {successMessage ? (
            <div className="success-message-active">
              Your profile information has been updated successfully
            </div>
          ) : (
            <div className="success-message-inactive">
              Your profile information has been updated successfully
            </div>
          )}
          <div className="social-media-icons">
            <SocialMediaIcons {...user} />
          </div>
        </form>
      </div>
      <div className="social-media-page">
        <div className="form-field-wrapper">
          <h2>Connect Social Media Accounts</h2>
          <div className="form-wrapper" elevation={3}>
            <div className="form" method="POST">
              <div className="message-wrapper">
                <h1 hidden={!updateSuccessful || error}>
                  Added link successfully!
                </h1>
                <h1 hidden={!error} style={{ color: "red" }}>
                  Invalid social media link...
                </h1>
              </div>
              <div className="social-media-item-wrapper">
                <label htmlFor="facebook">
                  <i className="fab fa-facebook-f"></i>
                </label>
                <TextField
                  required
                  id="facebook"
                  name="facebook"
                  type="text"
                  placeholder="Facebook"
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                  variant="outlined"
                  size="small"
                />
                <Button
                  className="confirm-button"
                  type="submit"
                  onClick={() => saveSocialLink("facebook")}
                >
                  Save
                </Button>
              </div>
              <div className="social-media-item-wrapper">
                <label htmlFor="instagram">
                  <i className="fab fa-instagram"></i>
                </label>
                <TextField
                  id="instagram"
                  name="instagram"
                  type="text"
                  placeholder="Instagram"
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                  variant="outlined"
                  size="small"
                />
                <Button
                  className="confirm-button"
                  type="submit"
                  onClick={() => saveSocialLink("instagram")}
                >
                  Save
                </Button>
              </div>
              <div className="social-media-item-wrapper">
                <label htmlFor="linkedin">
                  <i className="fab fa-linkedin-in"></i>
                </label>
                <TextField
                  id="linkedin"
                  name="linkedin"
                  type="text"
                  placeholder="Linkedin"
                  value={linkedin}
                  onChange={(e) => setLinkedin(e.target.value)}
                  variant="outlined"
                  size="small"
                />
                <Button
                  className="confirm-button"
                  type="submit"
                  onClick={() => saveSocialLink("linkedin")}
                >
                  Save
                </Button>
              </div>
              <div className="social-media-item-wrapper">
                <label htmlFor="twitter">
                  <i className="fab fa-twitter"></i>
                </label>
                <TextField
                  id="twitter"
                  name="twitter"
                  type="text"
                  placeholder="Twitter"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                  variant="outlined"
                  size="small"
                />
                <Button
                  className="confirm-button"
                  type="submit"
                  onClick={() => saveSocialLink("twitter")}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoProfileForm;
