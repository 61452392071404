import { useEffect, useState, useContext, useCallback } from "react";

import { MeContext } from "../../context/UserContext";
import asyncAPICall from "../../util/apiWrapper";

function ActivityLog(props) {
	const { user_id } = props.match.params;
	const { me } = useContext(MeContext);
	const [events, setEvents] = useState([]);

	const deleteActivity = (log_id) => {
		asyncAPICall(
			`/activity/delete`,
			"POST",
			{
				log_id: log_id,
				user_id: user_id,
			},
			() => {
				setEvents((e) => e.filter((activity) => activity.log_id !== log_id));
			},
			null,
			(err) => {
				console.log("Delete activity error: ", err);
			}
		);
	};

	const loadActivities = useCallback(() => {
		asyncAPICall(
			`/activity/get/${user_id}`,
			"GET",
			null,
			null,
			(data) => {
				setEvents(data);
			},
			(err) => {
				console.log("Get activities error: ", err);
			},
			true,
			null
		);
	}, [user_id]);

	useEffect(() => {
		if (me.user_id !== user_id) {
			props.history.push("/no-match");
		} else {
			loadActivities();
		}
	}, [loadActivities, me.user_id, props.history, user_id]);

	return (
		<div className="activity-log">
			<div className="activity-header">
				<h1>Activity Log</h1>
			</div>
			<div className="date-events">
				<ul>
					{events.map((event) => {
						return (
							<div key={event.log_id} className="cards">
								<li>
									<div className="card-header">
										<div className="event">
											<h1>Logged in at:</h1>
											<h2>{`${event.log_date.split("T")[0]} ${
												event.log_date.split("T")[1].split(".")[0]
											}`}</h2>
										</div>
										<div className="x-button">
											<button onClick={() => deleteActivity(event.log_id)}>
												<i className="fas fa-times"></i>
											</button>
										</div>
									</div>
								</li>
							</div>
						);
					})}
				</ul>
			</div>
		</div>
	);
}

export default ActivityLog;
