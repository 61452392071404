import { useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";

import SecurityWrapper from "../../util/securityWrapper";
import { MeContext } from "../../context/UserContext";

const NavbarMobile = ({
  searchTerm,
  setSearchTerm,
  isActive,
  setIsActive,
  userID,
}) => {
  const { logout } = useContext(MeContext);
  const history = useHistory();

  return (
    <div className={isActive ? "sidebar-active" : "sidebar sidebar-inactive"}>
      <div
        className={`toggle-btn ${isActive ?? "active"}`}
        onClick={() => setIsActive((i) => !i)}
      >
        <i className={`fas fa-${isActive ? "times" : "bars"}`}></i>
      </div>
      <ul className={`${isActive ? "sidebar-menu-active" : "side-menu-list"}`}>
        <li className="search-bar">
          <form
            onSubmit={(e) => {
              setIsActive(false);
              e.preventDefault();
              history.push("/search");
            }}
          >
            <input
              type="text"
              placeholder="&#xf002; "
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <label
              style={{ visibility: searchTerm ? "hidden" : "visible" }}
              htmlFor="mobile-search"
            >
              Search...
            </label>
          </form>
        </li>
        <SecurityWrapper restrict_roles="user">
          <li>
            <NavLink to="/admin/users" onClick={() => setIsActive(false)}>
              Admin
            </NavLink>
          </li>
        </SecurityWrapper>

        <li>
          <NavLink
            exact
            to={`/home/${userID}`}
            onClick={() => setIsActive(false)}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to={`/contact-info/${userID}`}
            onClick={() => setIsActive(false)}
          >
            My Profile
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to={`/family-members`}
            onClick={() => setIsActive(false)}
          >
            All Family
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/family-list" onClick={() => setIsActive(false)}>
            Family List
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/login" onClick={logout}>
            Log Out
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default NavbarMobile;
