import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Modal from "react-modal";

import asyncAPICall from "../../util/apiWrapper";
import useDeepEffect from "../hooks/useDeepEffect";
import SearchResultItem from "./search/searchResultItem";
import AddUserToList from "../addUserToList";
import { MeContext } from "../../context/UserContext";
import useWindowSize from "../hooks/useWindowSize";
import Button from "../core/Button";
import { errorToast } from "../../util/toastNotifications";

Modal.setAppElement("#root");

const SingleList = () => {
  const { list_id } = useParams();
  const history = useHistory();
  const [listData, setListData] = useState({});
  const [search, setSearch] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const { me } = useContext(MeContext);
  const { width } = useWindowSize();
  const [modalStyle, setModalStyle] = useState({
    overlay: {
      position: "fixed",
      backgroundColor: "rgba(0, 0, 0, 0.65)",
      overflow: "hidden",
    },
    content: {
      position: "absolute",
      maxWidth: "100%",
      top: "25%",
      left: "25%",
      right: "25%",
      bottom: "25%",
      border: "1px solid white",
      background: "#FAFAFA",
      overflow: "scroll",
      borderRadius: "7px",
      outline: "none",
      padding: "20px",
    },
  });

  useEffect(() => {
    if (width <= 500) {
      setModalStyle((ms) => ({
        ...ms,
        content: {
          ...ms.content,
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "10%",
        },
      }));
    } else {
      setModalStyle((ms) => ({
        ...ms,
        content: {
          ...ms.content,
          top: "25%",
          left: "25%",
          right: "25%",
          bottom: "25%",
        },
      }));
    }
  }, [width]);

  useEffect(() => {
    asyncAPICall(
      `/user-list/${me.user_id}/${list_id}`,
      "GET",
      null,
      null,
      (data) => {
        if (data) {
          setListData(data);
          data.users.forEach((user) => {
            user.isChecked = false;
          });
          setListUsers(
            data.users.filter((user) => user.user_id !== me.user_id)
          );
        }
      },
      null
    );
  }, [modalIsOpen, me.user_id, list_id]);

  useEffect(() => {
    const { users } = listData;

    if (!search && users) {
      setListUsers(users.filter((user) => user.user_id !== me.user_id));
    } else {
      setListUsers((lu) =>
        lu.filter(
          (person) =>
            person.first_name.toLowerCase().includes(search) ||
            person.last_name.toLowerCase().includes(search)
        )
      );
    }
  }, [search, listData, me.user_id]);

  const handleSelectAll = () => {
    const stateUsers = [...listUsers];
    stateUsers.forEach((user) => {
      if (selectAll) {
        user.isChecked = false;
        setSelected([]);
      } else {
        user.isChecked = true;
        const addUsers = stateUsers;
        setSelected(addUsers);
      }
    });

    setSelectAll(!selectAll);
  };

  const renderListMembers = () => {
    if (listUsers?.length > 0) {
      return listUsers
        .filter((person) => {
          return person.user_id !== me.user_id;
        })
        .map((person) => {
          return (
            <SearchResultItem
              person={person}
              searchTerm={search}
              selected={selected}
              setSelected={setSelected}
              displayCheckbox={true}
              displayLink={true}
              key={person.user_id}
            />
          );
        });
    } else {
      return <h1>No list members found.</h1>;
    }
  };

  const renderUsersNames = () => {
    if (selected) {
      return selected.map((user) => {
        return [` ${user.first_name} ${user.last_name}`];
      });
    }
  };

  const renderUsersEmails = () => {
    const userEmailsArray = [];

    if (selected) {
      selected.forEach((user) => {
        userEmailsArray.push(user.email);
      });
    }
    return userEmailsArray;
  };

  const handleRemoveUser = (e) => {
    e.preventDefault();

    asyncAPICall(
      "/list/remove-user",
      "POST",
      {
        selected,
        list_id,
      },
      null,
      (data) => {
        setListUsers(data.users);
        setListData(data);
      },
      null,
      (err) => {
        console.log(err);
      },
      false
    );

    setOpenConfirm(false);
    setSelected([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    asyncAPICall(
      `/list/delete-list/${list_id}`,
      "DELETE",
      null,
      (response) => {
        if (response.status < 400) {
          history.push("/family-list");
        }
      },
      null,
      null,
      (err) => {
        console.log(err);
        setIsLoading(false);
      },
      false
    );
  };

  const removeDuplicateObjects = (originalArray, uniqueKeyAsString) => {
    const valueArray = originalArray.map((obj) => obj[uniqueKeyAsString]);
    const removeDuplicates = Array.from(new Set(valueArray));
    return removeDuplicates.map((el) => {
      return originalArray.filter((obj) => {
        return obj[uniqueKeyAsString] === el;
      })[0];
    });
  };

  useDeepEffect(() => {
    if (listUsers.length > 0) {
      const unselectedUsers = removeDuplicateObjects(listUsers, "user_id");

      unselectedUsers.forEach((user) => {
        user.isChecked = false;
      });
      setListUsers(unselectedUsers);

      if (selected.length < listUsers.length) {
        setSelectAll(false);
      } else {
        setSelectAll(true);
      }
    } else {
      setSelectAll(false);
    }
  }, [selected, listUsers]);

  useDeepEffect(() => {
    if (selected.length > 0) {
      const selectedUsers = removeDuplicateObjects(selected, "user_id");

      selectedUsers.forEach((user) => {
        user.isChecked = true;
      });
      setSelected(selectedUsers);
    }
  }, [selected]);

  return (
    <div className="single-list-page-wrapper">
      <div className="content-wrapper">
        <div className="single-list-title">
          <h1>{listData.list_name || "No List Name"}</h1>
        </div>
        <div className="searchbar">
          <input
            type="text"
            placeholder="SEARCH"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="buttons-wrapper">
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Inline Styles Modal"
            style={modalStyle}
          >
            <div className="exit-button">
              <button onClick={() => setModalIsOpen(false)}>
                <i className="fas fa-times"></i>
              </button>
            </div>

            <AddUserToList listUsers={listUsers} />
          </Modal>
          <div className="send-button">
            {selected.filter((person) => !person.verified).length > 0 ? (
              <button
                disabled={selected.length > 0 ? false : true}
                className="selected-btn"
                onClick={() =>
                  errorToast("Cannot send messages to unverified persons")
                }
              >
                Send Message to Selected
              </button>
            ) : (
              <Link
                className="send-message-btn"
                to={{
                  pathname: "/send-message",
                  state: {
                    recipients: renderUsersNames(),
                    recipientsEmail: renderUsersEmails(),
                    sender: me.email,
                  },
                }}
              >
                <button disabled={selected.length > 0 ? false : true}>
                  Send Message
                </button>
              </Link>
            )}

            <Modal
              isOpen={openConfirm}
              onRequestClose={() => setOpenConfirm(false)}
              style={modalStyle}
            >
              <div className="exit-button">
                <button onClick={() => setOpenConfirm(false)}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="confirm-remove-form">
                <h1>Do you want to delete these users</h1>

                <div className="name-wrapper">
                  {selected.map((user) => (
                    <h2
                      key={user.user_id}
                    >{`${user.first_name} ${user.last_name}`}</h2>
                  ))}
                </div>

                <button onClick={handleRemoveUser}>Confirm</button>
              </div>
            </Modal>
          </div>
          <div className="add-button">
            <button className="add-user" onClick={() => setModalIsOpen(true)}>
              Add User
            </button>
          </div>
          <div className="remove-button">
            <button
              className={selected.length > 0 ? "" : "disabled"}
              onClick={() => setOpenConfirm(true)}
              disabled={selected.length > 0 ? false : true}
            >
              Remove User
            </button>
          </div>
          <form className="delete-list" onSubmit={handleSubmit}>
            <Button
              type="submit"
              isDisabled={isLoading}
              command={
                isLoading ? (
                  <i className="fas fa-spinner fa-spin" />
                ) : (
                  "Delete List"
                )
              }
            >
              Confirm Delete List
            </Button>
          </form>
        </div>

        <div className="select-all-wrapper">
          <input
            type="checkbox"
            id="select-all-checkbox"
            onClick={handleSelectAll}
            onChange={() => {}} // necessary for access to 'checked' property.
            checked={selectAll}
            disabled={listUsers.length > 0 ? false : true}
          />{" "}
          <h2>Select All</h2>
        </div>
        <div className="list-members-wrapper">{renderListMembers()}</div>
      </div>
    </div>
  );
};

export default SingleList;
