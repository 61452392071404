import Cookies from "js-cookie";

import UserList from "./userList";
import SecurityWrapper from "../../util/securityWrapper";

export default function UserListPage(props) {
  const authToken = Cookies.get("auth_token");

  return (
    <div className="list-wrapper">
      <SecurityWrapper roles="super-admin,admin">
        <UserList {...props} authToken={authToken} />
      </SecurityWrapper>
      <SecurityWrapper roles="user">
        <UserList
          {...props}
          authToken={authToken}
          columns="first_name,last_name,email,phone,active"
        />
      </SecurityWrapper>
    </div>
  );
}
