import { version as uuidVersion, validate as uuidValidate } from "uuid";

export function validateUUID(uuid_string) {
  return uuidValidate(uuid_string) && uuidVersion(uuid_string) === 4;
}

function formatPhone(phoneNumber) {
  let cleaned = ("" + phoneNumber).replace(/\D/g, "");

  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return phoneNumber;
}

function isValidName(name) {
  return name.length > 0;
}

function isValidEmail(email) {
  if (/^\w+([_+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
    return true;
  } else {
    return false;
  }
}

function isValidSocail(url) {
  return /^(https?:\/\/)?((w{3}\.)?)((facebook)|(instagram)|(twitter)|(linkedin)).com\/.*/i.test(
    url
  );
}

function isValidPassword(password) {
  return password.length > 0;
}

function areMatchingPasswords(password, confirmedPassword) {
  return password === confirmedPassword;
}

function isValidPhoneNumber(phone) {
  const basicNumberCheck =
    /^[0-9]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  return phone.match(basicNumberCheck) !== null;
}

function stripCharactersFromPhone(phone) {
  return phone.replace(/\D/g, "");
}

export {
  isValidName,
  isValidEmail,
  isValidPassword,
  areMatchingPasswords,
  isValidPhoneNumber,
  stripCharactersFromPhone,
  formatPhone,
  isValidSocail,
};
