import Cookies from "js-cookie";

const api_host = process.env.REACT_APP_API_HOST || "localhost:8089";
const api_protocol = process.env.REACT_APP_API_PROTOCOL || "http";

export default function asyncAPICall(
  api_endpoint,
  method = "GET",
  body = {},
  response_callback_method = null,
  data_callback_method = null,
  catch_callback_method = null,
  require_auth_token = true,
  headers = null,
  doNotStringifyBody = false,
  signal = null
) {
  let auth_token = Cookies.get("auth_token");

  if (!require_auth_token) {
    auth_token = "not_required";
  } else {
    if (!auth_token || auth_token === "") {
      console.log("Auth Token Required");
      return false;
    }
    let expiration = Cookies.get("auth_expires");
    if (Date.parse(expiration) < Date.now()) {
      // We have an expired token, so, break
      console.log("Expired Auth Token");
      return false;
    }
  }

  if (auth_token) {
    if (!headers) {
      headers = {
        "content-type": "application/json",
        auth_token,
      };
    } else {
      if (!("auth_token" in headers)) {
        headers["auth_token"] = auth_token;
      }
    }
    let payload = { method: method, headers: headers };
    if (method === "POST") {
      if (doNotStringifyBody) {
        payload.body = body;
      } else {
        payload.body = JSON.stringify(body);
      }
    }
    let response_function = (response) => {
      let error = new Error(response.statusText);
      if (response.ok) {
        return response.json();
      } else if (response.status === 403 || response.status === 401) {
        error.response = response;
        throw error;
      }
    };
    if (response_callback_method) {
      response_function = response_callback_method;
    }
    let data_function = (data) => {};
    if (data_callback_method) {
      data_function = data_callback_method;
    }
    let catch_function = (error) => console.log(error);
    if (catch_callback_method) {
      catch_function = catch_callback_method;
    }

    if (signal) {
      payload.signal = signal;
    }

    fetch(`${api_protocol}://${api_host}${api_endpoint}`, payload)
      .then((response) => response_function(response))
      .then((response) => data_function(response))
      .catch((response) => catch_function(response));

    return true;
  } else {
    return false;
  }
}

export function awaitAPICall(
  api_endpoint,
  method = "GET",
  body = {},
  response_callback_method = null,
  data_callback_method = null,
  catch_callback_method = null,
  require_auth_token = true
) {
  let auth_token = Cookies.get("auth_token");
  let expiration = Cookies.get("auth_expires");
  if (Date.parse(expiration) < Date.now()) {
    // We have an expired token, so, break
    console.log("Expired Auth Token");
    return false;
  }

  if (!require_auth_token) {
    auth_token = "not_required";
  }
  if (auth_token) {
    let payload = {
      method: method,
      headers: {
        "content-type": "application/json",
        auth_token,
      },
    };
    if (method === "POST") {
      payload.body = JSON.stringify(body);
    }
    let response_function = (response) => {
      const error = new Error(response.statusText);
      if (response.ok) {
        return response.json();
      } else if (response.status === 403 || response.status === 401) {
        error.response = response;
        throw error;
      }
    };
    if (response_callback_method) {
      response_function = response_callback_method;
    }
    let data_function = (data) => {};
    if (data_callback_method) {
      data_function = data_callback_method;
    }
    let catch_function = (error) => console.log(error);
    if (catch_callback_method) {
      catch_function = catch_callback_method;
    }

    let fetchFromAPI = async () => {
      try {
        let response = await fetch(
          `${api_protocol}://${api_host}${api_endpoint}`,
          payload
        );

        let results = await response_function(response);
        await data_function(results);
      } catch (error) {
        catch_function(error);
        return false;
      }
    };

    fetchFromAPI();

    return true;
  } else {
    return false;
  }
}
