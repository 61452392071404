import { useState, useEffect } from "react";

import { isValidEmail } from "../../util/stringUtils";
import asyncAPICall from "../../util/apiWrapper";
import Button from "../core/Button";
import { successfulToast } from "../../util/toastNotifications";

const AddUserForm = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [relationship, setRelationship] = useState("");
  const [disclaimer, setDisclaimer] = useState(false);
  const [deceased, setDeceased] = useState(false);
  const [deathDate, setDeathDate] = useState(null);
  const [deathDateError, setDeathDateError] = useState(false);
  const [deathBirthOrderError, setDeathBirthOrderError] = useState(false);
  const [birthDate, setBirthDate] = useState("");
  const [birthDateError, setBirthDateError] = useState(false);
  const [invite, setInvite] = useState(false);
  const [failMessage, setFailMessage] = useState(false);
  const [confirmUser, setConfirmUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [parentsValidError, setParentsValidError] = useState(false);
  const [spouseValidError, setSpouseValidError] = useState(false);

  useEffect(() => {
    props.relationshipType === "parent" && setRelationship("parent");
  }, [props.relationshipType]);

  useEffect(() => {
    if (Date.now() < Date.parse(birthDate)) {
      setBirthDateError(true);
    } else {
      setBirthDateError(false);
    }

    if (deceased) {
      if (Date.now() < Date.parse(deathDate)) {
        setDeathDateError(true);
      } else {
        setDeathDateError(false);
      }

      if (Date.parse(deathDate) < Date.parse(birthDate)) {
        setDeathBirthOrderError(true);
      } else {
        setDeathBirthOrderError(false);
      }
    } else {
      setDeathDate(null);
      setDeathDateError(false);
      setDeathBirthOrderError(false);
    }
  }, [birthDate, deceased, deathDate]);

  useEffect(() => {
    if (props.family) {
      if (props.family.parents.length >= 2) {
        setParentsValidError(true);
      } else {
        setParentsValidError(false);
      }

      if (props.family.spouse.length >= 1) {
        setSpouseValidError(true);
      } else {
        setSpouseValidError(false);
      }
    }
  }, [props.family]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFailMessage(false);
    setIsLoading(true);

    if (
      !birthDateError &&
      !deathDateError &&
      !deathBirthOrderError &&
      !(parentsValidError && relationship === "parent") &&
      !(spouseValidError && relationship === "spouse")
    ) {
      asyncAPICall(
        "/add/relationship",
        "POST",
        {
          user_one_id: props.me,
          relationship_type: relationship,
          invite: invite,
          invite_email: email,
          first_name: firstName,
          last_name: lastName,
          birth_date: birthDate,
          death_date: deathDate,
        },
        (res) => {
          setIsLoading(false);
          if (res.status < 400) {
            successfulToast("Successfully added user.");
            props.setModalIsOpen(false);
          }
        },
        null,
        (err) => {
          console.log("Add user error: ", err);
          setFailMessage(true);
          setIsLoading(false);
        }
      );
    }
  };

  const checkValidEmail = () => {
    if (!emailError) {
      asyncAPICall(
        "/check-email",
        "POST",
        {
          email: email,
        },
        (res) => {
          if (res.status >= 400) {
            setDuplicateEmail(true);
          } else {
            setEmailError(false);
            setDuplicateEmail(false);
          }
        },
        null,
        (err) => console.error("Duplicate Email Error: ", err),
        false
      );
    }
  };

  useEffect(() => {
    if (!email) {
      setEmailError(false);
    } else {
      setEmailError(!isValidEmail(email));
      setDuplicateEmail(false);
    }
  }, [email]);

  return (
    <div className="add-user-form-wrapper">
      <h1>Add User</h1>

      <div className="form-wrapper" style={{ paddingTop: "40px" }}>
        <form onSubmit={handleSubmit}>
          <input
            required
            type="text"
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
          />

          <input
            required
            type="text"
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
          />

          <input
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => checkValidEmail()}
            style={{ marginBottom: "4px" }}
          />

          {emailError && (
            <div className="birthdate-error" style={{ color: "red" }}>
              Invalid Email
            </div>
          )}

          {duplicateEmail && (
            <div className="birthdate-error" style={{ color: "red" }}>
              Duplicate Email
            </div>
          )}

          <label style={{ fontSize: "14px" }}>Birthdate</label>
          <input
            type="date"
            placeholder="Birthdate"
            onChange={(e) => setBirthDate(e.target.value)}
            style={{ marginBottom: "4px" }}
          />

          {birthDateError ? (
            <div className="birthdate-error">
              Birthdate cannot be set for a future date
            </div>
          ) : (
            ""
          )}

          <div className="relationship-deceased-wrapper">
            <div className="relationship-wrapper">
              <h2>Relationship: </h2>

              <select
                required
                onClick={() => setDisclaimer(true)}
                onChange={(e) => setRelationship(e.target.value.toLowerCase())}
                defaultValue={
                  props.relationshipType === "parent" ? "Parent" : ""
                }
              >
                <option value="" disabled>
                  Select Relationship
                </option>
                <option>Parent</option>
                <option>Spouse</option>
                <option>Child</option>
              </select>
            </div>

            {relationship === "spouse" && spouseValidError ? (
              <div className="spouse-error">You can only add one spouse</div>
            ) : (
              ""
            )}

            {relationship === "parent" && parentsValidError ? (
              <div className="parents-error">
                You can only add up to two parents
              </div>
            ) : (
              ""
            )}

            {disclaimer ? (
              <div className="disclaimer">
                *To extend your family and add more relationships, please go
                through your other family members. **For example, to add a
                sibling, click on your parent and add a child.
              </div>
            ) : (
              ""
            )}

            <div className="deceased-check">
              <h2>Deceased?</h2>

              <input
                type="checkbox"
                className="checkbox"
                onChange={() => {
                  setDeceased((d) => !d);
                  if (deceased && invite) {
                    setInvite((i) => !i);
                  }
                }}
              />
            </div>

            {deceased === true && (
              <div className="death-date-wrapper">
                <div className="death-date">
                  <label style={{ fontSize: "14px" }}>Deathdate</label>
                  <input
                    type="date"
                    id="death-date"
                    onChange={(e) => setDeathDate(e.target.value)}
                  />
                </div>

                {deathDateError ? (
                  <div className="death-error">
                    Deathdate cannot be set for a future date
                  </div>
                ) : (
                  ""
                )}

                {deathBirthOrderError ? (
                  <div className="death-error">
                    Deathdate cannot be set prior to birthdate
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            {!deceased && (
              <div className="invite-user-wrapper">
                <h2>Invite User?</h2>
                <input type="checkbox" onChange={() => setInvite((i) => !i)} />
              </div>
            )}
          </div>

          {confirmUser ? (
            <div className="confirm-message">
              *If the information above is correct click confirm*
              <div>
                <button
                  type="submit"
                  disabled={
                    !duplicateEmail &&
                    !emailError &&
                    !birthDateError &&
                    !deathDateError &&
                    !deathBirthOrderError &&
                    !(parentsValidError && relationship === "parent") &&
                    !(spouseValidError && relationship === "spouse")
                      ? false
                      : true
                  }
                >
                  {isLoading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </div>
          ) : (
            <Button
              type="submit"
              handleClick={() => setConfirmUser((prev) => !prev)}
              isDisabled={
                !duplicateEmail &&
                !emailError &&
                !duplicateEmail &&
                !birthDateError &&
                !deathDateError &&
                !deathBirthOrderError &&
                !(parentsValidError && relationship === "parent") &&
                !(spouseValidError && relationship === "spouse") &&
                relationship !== ""
                  ? false
                  : true
              }
              command={
                isLoading ? (
                  <i className="fas fa-spinner fa-spin" />
                ) : (
                  "Add User"
                )
              }
            />
          )}

          {failMessage ? (
            <div className="fail-error">Failed to add user</div>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default AddUserForm;
