import { useState, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { FaHome, FaUsers, FaThList, FaTools } from "react-icons/fa";
import { GoTriangleDown } from "react-icons/go";
import Modal from "react-modal";

import DropdownMenu from "./DropdownMenu";
import Avatar from "../../img/default-avatar.png";
import { MeContext } from "../../context/UserContext";
import SecurityWrapper from "../../util/securityWrapper";

Modal.setAppElement("#root");

const NavbarDesktop = ({ searchTerm, setSearchTerm, handleClick }) => {
  const { me } = useContext(MeContext);
  const [dropdownIsOpen, setDropdownOpen] = useState(false);
  const history = useHistory();

  return (
    <>
      <div className="navbar-left">
        <div className="search-bar-wrapper">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              history.push("/search");
            }}
          >
            <input
              className="search-bar"
              type="search"
              placeholder="&#xf002; Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ height: "30px", lineHeight: "normal" }}
            />
          </form>
        </div>
      </div>
      <div className="navbar-right">
        <div className="nav-links">
          <SecurityWrapper restrict_roles={"user"}>
            <NavLink
              to="/admin/users"
              className="nav-link"
              activeClassName="active-page"
            >
              <FaTools size="2em" />
              ADMIN
            </NavLink>
          </SecurityWrapper>

          <NavLink
            to={`/home/${me.user_id}`}
            className="nav-link"
            activeClassName="active-page"
          >
            <FaHome size="2em" />
            HOME
          </NavLink>

          <NavLink
            to="/family-list"
            className="nav-link"
            activeClassName="active-page"
          >
            <FaThList size="2em" />
            FAMILY LIST
          </NavLink>
          <NavLink
            to="/family-members"
            className="nav-link"
            activeClassName="active-page"
          >
            <FaUsers size="2em" />
            VIEW FAMILY
          </NavLink>
        </div>
        <div className="avatar">
          <img
            src={me.avatar_link ? me.avatar_link : Avatar}
            alt="avatar"
            onClick={handleClick}
          />
          <div
            className="avatar-text"
            onClick={() => setDropdownOpen((d) => !d)}
          >
            ME <GoTriangleDown size="1em" />
            {dropdownIsOpen && (
              <DropdownMenu
                userID={me.user_id}
                setDropdownOpen={setDropdownOpen}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarDesktop;
