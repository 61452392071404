import { useState, useContext } from "react";
import { Route, Switch } from "react-router-dom";

import Header from "./navigation/header";
import Home from "./pages/home/home";
import User from "./pages/userGet";
import UserListPage from "./pages/userListPage";
import UserForm from "./pages/userForm";
import ContactInfoProfileForm from "./pages/contactInfoProfileForm";
import ContactInfoProfile from "./pages/contactInfoProfile";
import AllFamilyMembers from "./pages/family-members/allFamilyMembers";
import SendMessage from "./pages/sendMessage";
import FamilyList from "./pages/familyList";
import SearchResults from "./pages/search/searchResults";
import FamilyContact from "./pages/familyContact";
import NoMatch from "./pages/noMatch";
import SingleList from "./pages/singleList";
import ActivityLog from "./pages/activityLog";
import QuickLists from "./pages/quickLists";
import ManageCSV from "./pages/manageCSV";
import Loading from "./core/loading";
import { MeContext } from "../context/UserContext";
import SecurityWrapper from "../util/securityWrapper";

const DefaultContainer = () => {
  const { authIsLoading } = useContext(MeContext);
  const [searchTerm, setSearchTerm] = useState("");

  if (authIsLoading) {
    return <Loading />;
  }

  return (
    <div className="container">
      <Route
        path="/"
        render={(routeProps) => (
          <Header
            {...routeProps}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        )}
      />

      <div className="body-wrapper">
        <SecurityWrapper roles="super-admin,admin,user">
          <Switch>
            <Route path="/home/:user_id" component={Home} />
            <Route
              path="/profile/edit/:user_id"
              component={ContactInfoProfileForm}
            />
            <Route
              path="/contact-info/:user_id"
              component={ContactInfoProfile}
            />
            <Route path="/family-members" component={AllFamilyMembers} />
            <Route path="/activity/:user_id" component={ActivityLog} />
            <Route path="/add-contact" component={FamilyContact} />
            <Route path="/send-message" component={SendMessage} />
            <Route path="/family-list" component={FamilyList} />
            <Route path="/quick-list/:list_type" component={QuickLists} />
            <Route path="/list/:list_id" component={SingleList} />
            <Route path="/manage-csv/:user_id" component={ManageCSV} />

            <Route
              path="/search"
              render={(routeProps) => {
                return (
                  <SearchResults
                    {...routeProps}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                );
              }}
            />
            <SecurityWrapper roles="super-admin,admin">
              <Route exact path="/admin/users" component={UserListPage} />
              <Route exact path="/admin/user/:user_id" component={User} />
              <Route path="/admin/user/edit/:user_id" component={UserForm} />
              <Route path="/admin/user-add" component={UserForm} />
            </SecurityWrapper>

            <Route component={NoMatch} />
          </Switch>
        </SecurityWrapper>
      </div>
    </div>
  );
};

export default DefaultContainer;
