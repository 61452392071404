const SocialMediaIcons = ({ facebook, linkedin, instagram, twitter }) => {
  return (
    <div className="social-media-icons-wrapper">
      {facebook && (
        <a href={facebook} target="_blank" rel="noreferrer">
          <i className="fab fa-facebook-f"></i>
        </a>
      )}

      {instagram && (
        <a href={instagram} target="_blank" rel="noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
      )}

      {linkedin && (
        <a href={linkedin} target="_blank" rel="noreferrer">
          <i className="fab fa-linkedin-in"></i>
        </a>
      )}

      {twitter && (
        <a href={twitter} target="_blank" rel="noreferrer">
          <i className="fab fa-twitter"></i>
        </a>
      )}
    </div>
  );
};

export default SocialMediaIcons;
