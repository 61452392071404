import { Link } from "react-router-dom";

const DropdownItem = ({
  children,
  leftIcon,
  rightIcon,
  goToMenu,
  setActiveMenu,
  goTo,
  overrideClick,
}) => {
  return (
    <Link
      to={goTo}
      className="menu-item"
      onClick={() => {
        if (goToMenu) {
          setActiveMenu(goToMenu);
        }
        if (overrideClick) {
          overrideClick();
        }
      }}
    >
      <span className="icon-button">{leftIcon}</span>
      {children}
      <span className="icon-right">{rightIcon}</span>
    </Link>
  );
};

export default DropdownItem;
