// TODO:
// Fix or remove ReCAPTCHA. As is, not viable for production and doesn't validate.
import { useState, useEffect } from "react";
import { useHistory } from "react-router";

import asyncAPICall from "../../util/apiWrapper";
import Button from "../core/Button";
import { successfulToast } from "../../util/toastNotifications";

const SendMessage = (props) => {
  const [toUsers, setToUsers] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [wasSucessful, setWasSucessful] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const history = useHistory();

  const [recipients, setRecipients] = useState("");
  const [recipientsEmail, setRecipientsEmail] = useState("");

  useEffect(() => {
    if (props.location.state === undefined) {
      history.goBack();
    } else {
      setRecipients(props.location.state.recipients);
      setRecipientsEmail(props.location.state.recipientsEmail);
      setToUsers(recipients);
    }
  }, [recipients, recipientsEmail, history, props.location.state]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const getPromise = new Promise((res, rej) => {
      asyncAPICall(
        `/user/send-messages`,
        "POST",
        {
          email_addresses: recipientsEmail,
          message_subject: subject,
          message_content: message,
        },
        (response) => {
          res(response.status);
        },
        null,
        (err) => {
          console.log("Send Message: ", err);
          setMessageError(true);
          setTimeout(() => {
            setMessageError(false);
          }, 3000);
        }
      );
    });

    getPromise.then((res) => {
      if (res < 400) {
        setWasSucessful(true);
        successfulToast("Message sent! You will be redirected automatically.");
        setTimeout(() => {
          history.goBack();
        }, 2000);
      }
    });
  };

  return (
    <div className="page-wrapper">
      <form className="send-message-form" onSubmit={handleSubmit}>
        <div className="to-subject-wrapper">
          <div className="to-wrapper">
            <h1>TO:</h1> <h2>{`${toUsers} `}</h2>
          </div>
          <div className="subject-wrapper">
            <h1>SUBJECT:</h1>
            <input
              type="text"
              maxLength={30}
              onChange={(e) => setSubject(e.target.value)}
            />
            <h1>{30 - subject.length}</h1>
          </div>
        </div>
        <div className="message-send-btn-wrapper">
          <div className="message-body-wrapper">
            <h1>MESSAGE</h1>
            <textarea
              id="message-box"
              maxLength={900}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <h1>{900 - message.length}</h1>
          </div>

          <div className="btn-wrapper">
            <Button
              disabled={messageError || !message.length}
              type="submit"
              isDisabled={isLoading}
              command={
                isLoading ? <i className="fas fa-spinner fa-spin" /> : "Send"
              }
            >
              Send
            </Button>
            {wasSucessful && <h1>Message Sent!</h1>}
            {messageError && (
              <h1 className="error-message">
                There was a problem sending your message.
              </h1>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
export default SendMessage;
