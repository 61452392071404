import { useState } from "react";
import Modal from "react-modal";

import asyncAPICall from "../../util/apiWrapper";

export default function DeleteUserModal({ user, setList, setFilteredList }) {
  const [isOpen, setIsOpen] = useState(false);

  const modalStyle = {
    overlay: {
      position: "fixed",
      backgroundColor: "rgba(0, 0, 0, 0.65)",
      overflow: "hidden",
    },
    content: {
      position: "absolute",
      top: "25%",
      left: "30%",
      right: "30%",
      bottom: "25%",
      border: ".5px solid #FAFAFA",
      background: "#FAFAFA",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
    },
  };

  const deleteUser = (userId) => {
    setList((prev) => prev.filter((user) => user.user_id !== userId));
    setFilteredList((prev) => prev.filter((user) => user.user_id !== userId));
  };

  const handleRemoveUser = () => {
    const deleteUserPromise = new Promise((resolve, reject) => {
      asyncAPICall(
        `/user/delete/${user.user_id}`,
        "DELETE",
        null,
        (res) => {
          resolve(true);
        },
        null,
        (err) => reject(("Error Deleting User: ", err))
      );
    });

    setIsOpen(false);

    deleteUserPromise
      .then(() => deleteUser(user.user_id))
      .catch(console.error)
      .finally(() => setIsOpen(false));
  };

  return (
    <>
      <i className="fas fa-trash" onClick={() => setIsOpen(true)} />
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={modalStyle}
      >
        <div className="exit-button">
          <button onClick={() => setIsOpen(false)}>X</button>
        </div>
        <div className="delete-user-modal">
          <h1>
            {`Do you want to delete ${user.first_name} ${user.last_name}?`}
          </h1>
          <button onClick={handleRemoveUser}>Confirm</button>
        </div>
      </Modal>
    </>
  );
}
