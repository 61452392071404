import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";

import DefaultAvatar from "../../../img/default-avatar.png";
import Banner from "../../../img/profile-banner.jpg";
import asyncAPICall from "../../../util/apiWrapper";
import AddUserForm from "../addUserForm";
import MergeUserForm from "../../forms/mergeUserForm";
import SearchResultItem from "../search/searchResultItem";
import { MeContext } from "../../../context/UserContext";
import useWindowSize from "../../hooks/useWindowSize";
import useDebounce from "../../hooks/useDebounce";
import SocialMediaIcons from "../../core/socialMediaIcons";
import useAbortEffect from "../../hooks/useAbortEffect";
import RemoveFamilyMemberForm from "../../forms/RemoveFamilyMemberForm";

Modal.setAppElement("#root");

const Home = (props) => {
  const { user_id } = props.match.params;
  const { me } = useContext(MeContext);
  const { width } = useWindowSize();

  const [userData, setUserData] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [mergeModalIsOpen, setMergeModalIsOpen] = useState(false);
  const [relationshipType, setRelationshipType] = useState("");
  const [relationshipStatus, setRelationshipStatus] = useState("");
  const [family, setFamily] = useState({});
  const [searchFamily, setSearchFamily] = useState([]);
  const [selected, setSelected] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [verification, setVerification] = useState({});
  const [renderInvite, setRenderInvite] = useState(false);
  const [removeFamilyModal, setRemoveFamilyModal] = useState(false);

  const searchDebounce = useDebounce(searchTerm);
  const [modalStyle, setModalStyle] = useState({
    overlay: {
      position: "fixed",
      backgroundColor: "rgba(0, 0, 0, 0.65)",
    },
    content: {
      position: "absolute",
      maxWidth: "100%",
      top: "10%",
      left: "25%",
      right: "25%",
      bottom: "25%",
      border: ".5px solid #FAFAFA",
      background: "#FAFAFA",
      borderRadius: "7px",
      outline: "none",
      padding: "20px",
    },
  });

  useAbortEffect(
    (signal) => {
      setIsSearching(true);
      if (searchDebounce) {
        asyncAPICall(
          `/search/${searchDebounce}`,
          "GET",
          null,
          null,
          (data) => {
            const filterFamily = data.filter(
              (user) => me.user_id !== user.user_id
            );
            setSearchFamily(filterFamily);
            setIsSearching(false);
          },
          (err) => {
            console.error("Search error: ", err);
            setIsSearching(false);
          },
          true,
          null,
          true,
          signal
        );
      } else {
        setSearchFamily([]);
        setSearchTerm("");
        setIsSearching(false);
      }
    },
    [searchDebounce]
  );

  useAbortEffect(
    (signal) => {
      setModalStyle((ms) => {
        const styles =
          width <= 500
            ? {
                left: "5%",
                right: "5%",
                bottom: "10%",
              }
            : {
                left: "25%",
                right: "25%",
                bottom: "25%",
              };
        return { ...ms, content: { ...ms.content, ...styles } };
      });
    },
    [width]
  );

  useAbortEffect(
    (signal) => {
      if (me.user_id) {
        if (me.user_id !== user_id) {
          asyncAPICall(
            `/user/get/${user_id}`,
            "GET",
            null,
            null,
            (data) => {
              setVerification(data.verified);
              setUserData(data);
            },
            (err) => {
              console.error(err);
            },
            true,
            null,
            true,
            signal
          );

          asyncAPICall(
            `/user/relationship/${me.user_id}/${user_id}`,
            "GET",
            null,
            null,
            (data) => {
              setRelationshipStatus(data);
            },
            (err) => {
              console.error("Relationship fetch error", err);
            },
            true,
            null,
            true,
            signal
          );
        } else {
          setUserData(me);
          setRelationshipStatus("Self");
        }
      }
    },
    [user_id, me, props]
  );

  useAbortEffect(
    (signal) => {
      if (
        verification.verified === false &&
        verification.token === false &&
        !userData.under_age
      ) {
        setRenderInvite(true);
      } else {
        setRenderInvite(false);
      }
    },
    [userData, verification]
  );

  useAbortEffect(
    (signal) => {
      if (!modalIsOpen) {
        asyncAPICall(
          `/user/get-family/${user_id}`,
          "GET",
          null,
          null,
          (data) => {
            setFamily(data);
          },
          (err) => {
            console.log("Fetch family error", err);
          },
          true,
          null,
          true,
          signal
        );
      }
    },
    [user_id, modalIsOpen]
  );

  const renderCards = () => {
    const renderFamily = (relType) => {
      return family[relType].map((member) => {
        return (
          <Link key={member.user_id} to={`${member.user_id}`}>
            <div className="family-member-wrapper">
              <img
                className="family-member-img"
                src={member.avatar_link || DefaultAvatar}
                alt="Family member avatar"
              />
              <h2>
                {member.first_name} {member.last_name}
              </h2>
            </div>
          </Link>
        );
      });
    };

    const memberArr = [];

    Object.keys(family).forEach((type) => {
      if (family[type].length > 0) {
        memberArr.push(
          <div className="parents-wrapper">
            <div className="title-wrapper">
              <div className="card-title">
                <h1>{type.toUpperCase()}</h1>
              </div>
              <div className="people">{renderFamily(type)}</div>
            </div>
          </div>
        );
      }
    });

    return memberArr.map((type, index) => {
      return <div key={index}>{type}</div>;
    });
  };

  const renderSearchResults = () => {
    if (isSearching)
      return (
        <div>
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      );

    return searchFamily.map((member) => {
      return (
        <SearchResultItem
          key={member.user_id}
          person={member}
          displayLink={true}
          displayCheckbox={true}
          selected={selected}
          setSelected={setSelected}
          displayRelationship={true}
        />
      );
    });
  };

  const renderSelected = () => {
    return selected.map((member) => {
      return (
        <SearchResultItem
          key={member.user_id}
          person={member}
          displayRelationship={true}
        />
      );
    });
  };

  const handleAddFamily = (type = null) => {
    if (type === "parent") {
      setRelationshipType("parent");
    }
    setModalIsOpen(true);
  };

  const handleAddRelationship = () => {
    setSelected([]);
    setSearchTerm("");
    asyncAPICall(
      "/add/relationship",
      "POST",
      {
        user_one_id: user_id,
        user_two_id: selected[0].user_id,
        relationship_type: relationshipType,
      },
      null,
      () => {
        setModalIsOpen(false);
      },
      (err) => {
        console.log(err);
        setModalIsOpen(false);
      },
      true
    );
  };

  const handleInvite = () => {
    asyncAPICall(
      "/user/invite",
      "POST",
      {
        invite_email: userData.email,
      },
      null,
      null,
      (err) => {
        console.error(err);
      }
    );
    setRenderInvite(false);
  };

  return (
    <div className="home-wrapper">
      <div
        className="images-wrapper"
        style={{
          backgroundImage: `url(${
            userData.banner_image ? userData.banner_image : Banner
          })`,
          backgroundSize: "cover",
          backgroundPosition: "left",
        }}
      >
        <img
          className="avatar-img"
          src={userData.avatar_link ? userData.avatar_link : DefaultAvatar}
          alt="Avatar"
        />
      </div>
      <div className="home-content-wrapper">
        <div className="profile-info-wrapper">
          <div className="profile-name">
            <h1>
              {userData.first_name && userData.last_name
                ? `${userData.first_name} ${userData.last_name}`
                : "No Name"}
            </h1>

            <div className="personal-info-wrapper">
              <div className="relationship-contact-info">
                <h2>{relationshipStatus}</h2>
                {relationshipStatus &&
                relationshipStatus !== "No Relationship found" ? (
                  <Link
                    to={{
                      pathname: `/contact-info/${user_id}`,
                      state: relationshipStatus,
                    }}
                  >
                    Contact Info
                  </Link>
                ) : null}
              </div>
              <div className="merge-invite-buttons">
                <MergeUserForm
                  family={family}
                  userData={userData}
                  setMergeModalIsOpen={setMergeModalIsOpen}
                  isOpen={mergeModalIsOpen}
                  width={width}
                />
                <div className="invite-user-button">
                  {renderInvite && user_id !== me.user_id && (
                    <button onClick={handleInvite}>Invite User</button>
                  )}
                </div>
                <div className="merge-button">
                  {family.parents?.length > 0 ||
                  family.children?.length > 0 ||
                  family.spouse?.length > 0 ? (
                    <button
                      onClick={() => setMergeModalIsOpen(true)}
                      hidden={me.user_id === user_id ? true : false}
                    >
                      <i className="fas fa-exchange-alt"></i> Merge
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-section">
          <div className="link-wrapper">
            <h1>ABOUT</h1>
            {user_id === me.user_id || userData.created_by === me.user_id ? (
              <Link to={`/profile/edit/${user_id}`}>
                <i className="fas fa-pencil-alt"></i>
              </Link>
            ) : null}
          </div>

          <p>{userData.bio ? userData.bio : "No bio added."}</p>
        </div>
        <div className="family-buttons">
          <div>
            <button className="add-btn" onClick={handleAddFamily}>
              Add +
            </button>
            {Object.values(family).every(
              (familyRelationship) => familyRelationship.length === 0
            ) ? (
              ""
            ) : (
              <button
                className="remove-btn"
                onClick={() => setRemoveFamilyModal(true)}
              >
                Remove -
              </button>
            )}
          </div>
        </div>
        <div className="cards">
          <div className="cards-wrapper">{renderCards()}</div>
        </div>

        <Modal
          isOpen={removeFamilyModal}
          onRequestClose={() => setRemoveFamilyModal(false)}
          style={modalStyle}
        >
          <RemoveFamilyMemberForm
            family={family}
            setFamily={setFamily}
            userData={userData}
            setRemoveFamilyModal={setRemoveFamilyModal}
          />
        </Modal>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => {
            setModalIsOpen(false);
            setSearchFamily([]);
            setSelected([]);
            setSearchTerm("");
          }}
          style={modalStyle}
        >
          <div className="exit-button">
            <button
              onClick={() => {
                setModalIsOpen(false);
                setSearchFamily([]);
                setSelected([]);
                setSearchTerm("");
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="find-family">
            <h1>Find Family</h1>
            <input
              type="search"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {selected.length > 0 ? renderSelected() : renderSearchResults()}
          </div>

          {selected.length > 0 ? (
            <div className="relationship-wrapper">
              <h2>Relationship: </h2>
              <select
                required
                onChange={(e) =>
                  setRelationshipType(e.target.value.toLowerCase())
                }
                defaultValue={relationshipType}
              >
                <option value="">Select Relationship</option>
                <option value="parent">Parent</option>
                <option value="spouse">Spouse</option>
                <option value="child">Child</option>
              </select>

              <button onClick={() => handleAddRelationship()}>Add</button>
            </div>
          ) : (
            <AddUserForm
              family={family}
              me={user_id}
              setModalIsOpen={setModalIsOpen}
              relationshipType={relationshipType}
            />
          )}
        </Modal>
      </div>

      <div className="footer-wrapper">
        <SocialMediaIcons {...userData} />
      </div>
    </div>
  );
};

export default Home;
